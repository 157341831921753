import React, {Suspense, useEffect, useState} from 'react';
import s from "./styles.module.scss"
import classNames from "classnames";
import CourseBox from "./CourseBox";
import Tabbar from "../../Sections/Tabbar/Tabbar";
import MobilePinnedMainHeader from "../../Sections/MobilePinnedMainHeader";
import {useQuery} from "react-query";
import AdminCanView from "../../Components/ViewLimit/AdminCanView";
import {Link} from "react-router-dom";
import LoadingPage, {FullPageLoading} from "../../Components/LoadingPage";
import ResponsivePagination from "react-responsive-pagination";
import axiosClient from "../../Api/apiClient";
import {active} from "sortablejs";
import PageTitle from "../../Components/PageTitle/PageTitle";
const Courses = () => {
    PageTitle("Courses")

    // 1 = my course , 2 = all courses , 3 bookmarks
    const [courseTab,setCourseTab]=useState(2)
    const [page,setPage]=useState(1)
    const [totalPages,setTotalPages]=useState(1)
    const perpage = 9

    const [myCoursesCount,setMyCoursesCount]=useState("...")


    useEffect(()=>{
        setPage(1)
    },[courseTab])

    const CourseList =({myCourseTab})=>{

        const {data} = useQuery({
            queryKey:["/courses",page,courseTab],
            queryFn :()=>axiosClient.get(`/courses?page=${page}&per_page=${perpage}${courseTab==3 ? `&bookmarked=1`:""}`),
            onSettled:(res)=>{
                if (courseTab != 3) {
                    setMyCoursesCount(res.data.resulting_data.my_courses.meta.total)
                }
            }
        })
        const myCourses =data.data.resulting_data?.my_courses.data
        const allCourses =data.data.resulting_data?.courses.data
        let activeArray= courseTab == 1? myCourses:allCourses

        const totalItems =courseTab == 1? data.data.resulting_data.my_courses.meta.total:data.data.resulting_data.courses.meta.total
        setTotalPages(Math.ceil(totalItems/perpage))



        return(
            <>
                 {  activeArray.length == 0?
                    <div className={"w-full h-full  flex-col gap-4 flex justify-center text-center items-center"}>
                        <svg width="99" height="98" viewBox="0 0 99 98" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.4" d="M71.6957 81.2677L74.7987 82.7532C78.0604 84.3147 81.8347 81.9349 81.8306 78.3155L81.791 28.2768C81.791 18.3842 76.2883 12.25 66.4119 12.25H36.6069C26.7064 12.25 21.3231 18.3842 21.3231 28.2768L21.2516 78.3077C21.2476 81.9272 25.0219 84.3106 28.2876 82.7455L31.3667 81.2714C33.218 80.3816 35.3594 80.3179 37.2625 81.0966L46.0267 84.6683C49.5588 86.1106 53.5196 86.1106 57.0517 84.6683L65.7998 81.0966C67.7031 80.3179 69.8444 80.3816 71.6957 81.2677Z" stroke="white" stroke-width="6.125" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M41.8597 59.0171H61.0012" stroke="white" stroke-width="6.125" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M57.649 46.698L45.3925 34.4453M45.4002 46.7016L57.6567 34.4453" stroke="white" stroke-width="6.125" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <div className={"text-24 lg:text-48 max-w-[520px] font-semibold"}>
                            {courseTab == 3 ?"You have not bookmarked any courses yet."
                                :
                                "Courses has not been added yet"
                            }
                        </div>
                        <p className={"text-14 max-w-[400px] text-gray-90"}>
                            {courseTab==3?
                                "When you bookmark a course, it will appear here."
                                :
                                "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content."
                            }
                        </p>
                        {!courseTab == 3 &&
                            <AdminCanView>
                                <Link to={"add"} className={"btn mt-1 grey-50  btn-normal"}>
                                    Add new
                                </Link>
                            </AdminCanView>
                        }

                    </div>
                     :
                     <>
                         <div className="courses-container flex w-full  py-8 px-8 ">
                             {
                                 [...activeArray].map(item =>
                                     <CourseBox key={item.id} data={item}/>
                                 )
                             }
                         </div>
                         <div className={"mt-auto"}>
                             <ResponsivePagination
                                 current={page}
                                 total={totalPages}
                                 onPageChange={setPage}
                             />
                         </div>
                     </>

                }
            </>
        )
    }
    return (
        <div className={classNames(s.container,"h-full flex flex-col")}>
           <MobilePinnedMainHeader title={"Courses"} >
               <AdminCanView>
                   <Link to={"add"}  className="btn grey-50">
                       Add new course
                   </Link>
               </AdminCanView>
           </MobilePinnedMainHeader>
            <Tabbar>
                <AdminCanView>
                    <div onClick={()=>setCourseTab(1)} className={classNames("tab",courseTab==1 && "active")}>
                        My courses
                        <div className="sqr rounded-[6px] grid place-items-center aspect-square w-6 bg-brown-110 text-12 text-gray-90 font-medium">
                            {myCoursesCount}
                        </div>
                        <div className={"sqr rounded-12 grid grid-cols-2 "}>

                        </div>
                    </div>
                </AdminCanView>
                <div onClick={()=>setCourseTab(2)} className={classNames("tab", courseTab == 2 && "active")}>
                    All courses
                </div>
                <div onClick={()=>setCourseTab(3)} className={classNames("tab", courseTab==3 && "active")}>
                    Marked
                </div>
            </Tabbar>
            <Suspense fallback={<FullPageLoading/>}>
                <div className={"flex-grow overflow-auto flex flex-col pb-4"}>
                        <CourseList courseTab={courseTab}/>

                </div>


            </Suspense>
        </div>
    );
};

export default Courses;



