import React, {useState,Suspense} from 'react';
import CryptocurrencyBox from "./CryptocurrencyBox/CryptocurrencyBox";
import s from "./style.module.scss"
import MobilePinnedMainHeader from "../../Sections/MobilePinnedMainHeader";
import Tabbar from "../../Sections/Tabbar/Tabbar";
import classNames from "classnames";
import {useQuery} from "react-query";
import {FullPageLoading} from "../../Components/LoadingPage";
import {Link} from "react-router-dom";
import AdminCanView from "../../Components/ViewLimit/AdminCanView";
import axiosClient from "../../Api/apiClient";
import ResponsivePagination from "react-responsive-pagination";
import PageTitle from "../../Components/PageTitle/PageTitle";
const Cryptocurrency = () => {
    PageTitle("Cryptocurrencies")

    const [isAllTab,setIsAllTab]=useState(true)

    const [page,setPage]=useState(1)
    const [totalPages,setTotalPages]=useState(1)
    const perpage = 15

    const CryptoList=()=>{
        const { data  } = useQuery({
            queryKey:["/bundles",isAllTab,page],
            queryFn :()=>axiosClient.get(`/bundles?page=${page}&per_page=${perpage}${isAllTab==false ? `&bookmarked=1`:""}`),
        })
        const listData = data.data.resulting_data.data

        const totalItems = data.data.resulting_data.meta.total
        setTotalPages(Math.ceil(totalItems/perpage))

        return (
            <>
                {listData.length == 0 ?

                    <div className={"w-full h-full col-span-2 flex-col gap-4 flex justify-center text-center items-center"}>
                        <svg width="99" height="98" viewBox="0 0 99 98" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.4"
                                  d="M71.6957 81.2677L74.7987 82.7532C78.0604 84.3147 81.8347 81.9349 81.8306 78.3155L81.791 28.2768C81.791 18.3842 76.2883 12.25 66.4119 12.25H36.6069C26.7064 12.25 21.3231 18.3842 21.3231 28.2768L21.2516 78.3077C21.2476 81.9272 25.0219 84.3106 28.2876 82.7455L31.3667 81.2714C33.218 80.3816 35.3594 80.3179 37.2625 81.0966L46.0267 84.6683C49.5588 86.1106 53.5196 86.1106 57.0517 84.6683L65.7998 81.0966C67.7031 80.3179 69.8444 80.3816 71.6957 81.2677Z"
                                  stroke="white" stroke-width="6.125" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M41.8597 59.0171H61.0012" stroke="white" stroke-width="6.125"
                                  stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M57.649 46.698L45.3925 34.4453M45.4002 46.7016L57.6567 34.4453" stroke="white"
                                  stroke-width="6.125" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <div className={"text-24 lg:text-48 max-w-[520px] font-semibold"}>
                            {isAllTab ?
                                "Bundles has not been added yet "
                                :
                                "You have not bookmarked any bundles."
                            }
                        </div>
                        <p className={"text-14 max-w-[400px] text-gray-90"}>
                            {isAllTab ? "" :
                                "When you bookmark a bundle, it will appear here."
                            }
                        </p>
                        {isAllTab && <AdminCanView>
                            <Link to={"add"} className={"btn mt-1 grey-50  btn-normal"}>
                                Add new
                            </Link>
                        </AdminCanView>}

                    </div>
                    :
                    <div className={"crypto-grid-box w-full  pad-layout py-8 flex-grow"}>
                        {[...listData].map(i => <CryptocurrencyBox id={i.id} isBookmarked={i.is_bookmarked}
                                                                   title={i.title}
                                                                   authorPic={i.owner.profile_image_url}
                                                                   authorName={i.owner.name}
                                                                   authorRole={i.owner.email} coins={i.coins}/>)}
                    </div>


                }
            </>
        )
    }

    return (
            <div className={`${s.container}  flex flex-col h-full w-full`}>
                <MobilePinnedMainHeader title={"Cryptocurrencies"}>
                    <AdminCanView>
                        <Link to={"add"}  className="btn grey-50">
                            Add new bundle
                        </Link>
                    </AdminCanView>
                </MobilePinnedMainHeader>
                <Tabbar>
                    <div onClick={()=>setIsAllTab(true)} className={classNames("tab cursor-pointer",isAllTab&&"active")}>
                        All bundles
                    </div>
                    <div onClick={()=>setIsAllTab(false)} className={classNames("tab cursor-pointer",!isAllTab&&"active")}>
                        Marked
                        {/*<div className="sqr rounded-[6px] grid place-items-center aspect-square w-6 bg-brown-110 text-12 text-gray-90 font-medium">*/}
                        {/*    4*/}
                        {/*</div>*/}
                    </div>

                </Tabbar>
                    <Suspense fallback={<FullPageLoading/>}>
                        <div className={"flex flex-col flex-grow overflow-auto pb-4"}>
                            <CryptoList/>


                            <div className={"mt-auto paginator-wrapper"}>
                                <ResponsivePagination
                                    current={page}
                                    total={totalPages}
                                    onPageChange={setPage}
                                />
                            </div>
                        </div>
                    </Suspense>
            </div>
    );
};

export default Cryptocurrency;
