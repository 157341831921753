import React, {useState,Suspense} from 'react';
import MobilePinnedMainHeader from "../../Sections/MobilePinnedMainHeader";
import classNames from "classnames";
import s from "./styles.module.scss"
import MentorBox from "./MentorBox";
import PopupContainer from "../../Sections/PopupContainer";
import {useMutation, useQuery} from "react-query";
import {FullPageLoading} from "../../Components/LoadingPage";
import AdminCanView from "../../Components/ViewLimit/AdminCanView";
import axiosClient from "../../Api/apiClient";
import BtnTextForMutation from "../../Components/BtnTextForMutation";
import PageTitle from "../../Components/PageTitle/PageTitle";
import AddMentor from "./AddMentor";
import {Link} from "react-router-dom";

const Mentors = () => {
    PageTitle("Mentors")

    const [isDeletePopOpen,setIsDeletePopOpen]=useState()
    const [isAddPopOpen,setIsAddPopOpen]=useState(false)

    const MentorsList =()=>{

        const { data  } = useQuery({
            queryKey:"/mentors",
        })
        const mentors = data.resulting_data.data
        return(
            <div className={" flex-grow flex  flex-col overflow-auto pad-layout" +
                " py-9"}>
                <div className={"mb-9 flex items-center justify-between"}>
                    <div className="title text-gray-90 font-medium text-18 ">
                        {mentors.length} Mentor
                    </div>

                    <AdminCanView>
                        <button onClick={()=>setIsAddPopOpen(true)} className={"btn transparent"}>
                            Add  new mentor
                        </button>
                    </AdminCanView>
                </div>
                <div className={"flex-grow "}>
                    <div className="grid-mentors    ">
                        {mentors.map(m=>
                            <MentorBox key={m.id} id={m.id} isOwner={m.roles.includes("super_admin")} name={m.name} role={m.occupation} about={m.about_me} img={m.profile_image_url} desc={"5 year exp"} />
                        )}
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div className={classNames(s.container,"h-full  flex flex-col overflow-hidden")}>
            <MobilePinnedMainHeader title={"Mentors"}>
                <AdminCanView>
                    <Link to={"edit"} className={"btn grey-50"}>
                        Edit my info
                    </Link>
                </AdminCanView>
            </MobilePinnedMainHeader>

            <Suspense  fallback={<FullPageLoading/>}>
                <MentorsList/>
            </Suspense>
            {isAddPopOpen
                &&
                <PopupContainer>
                    <AddMentor setIsAddPopOpen={setIsAddPopOpen}/>
                </PopupContainer>
            }
            {isDeletePopOpen &&
                <PopupContainer>
                    <div className={"pop-up  items-center flex justify-center flex-col p-8"}>
                        <div onClick={()=>{setIsDeletePopOpen(false)}} className="close  right-4 top-4 ">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 6L18 18" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
                                <path d="M18 6L6 18" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
                            </svg>
                        </div>

                        <div className="text-18 font-medium mb-3">
                            Delete mentor
                        </div>
                        <div className="font-medium text-gray-50 mb-8">
                            Are you sure?
                        </div>
                        <div className="btn-wrapper flex gap-4">
                            <div className="btn dark">
                                Cancel
                            </div>
                            <div className="btn w-[164px] red">
                                Delete
                            </div>
                        </div>
                    </div>
                </PopupContainer>
            }

        </div>
    );
};

export default Mentors;
