import React, {useEffect, useRef, useState} from 'react';
import classNames from "classnames";
import {useDropzone} from "react-dropzone";
import {DayPicker} from "react-day-picker";
import {format} from "date-fns";
import useOnClickOutside from "../../../Hooks/useOnClickOutside";
import {useMutation, useQueryClient} from "react-query";
import axiosClient from "../../../Api/apiClient";
import CustomSpinner from "../../../Utility/CustomSpinner";
import {uploadSingleFile} from "../../../Functions/UploadSingleFilePromise";
import {useAlert} from "react-alert";


const AdminSidebar = ({setIsOpened,isOpened}) => {
    const [activeType,setActiveType]=useState(0)
    const [filesIds,setFilesIds]=useState([])
    const [addedFiles,setAddedFiles] = useState([])
    const [dataToSend,setDataToSend]=useState({
        "title":"",
        "description":"",
        "type":activeType==0?"event":"note",
        "event_date":"",
        "file_ids":filesIds
    })
    const handleChange=(e)=>{
        const { name, value } = e.target;
        setDataToSend((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    }

    const { getRootProps, getInputProps} = useDropzone({
        multiple:true,
        // accept:{
        //     "application/msword":['.doc',".docx"],
        //     "application/pdf":['.pdf']
        // },
        onDrop: files => {
            setAddedFiles(prev=>[...prev,...files])
        }
    });
    const files = addedFiles.map((file,index) => {
        const removeFile=()=>{
            setAddedFiles( prevState=>
                [...prevState.slice(0,index), ...prevState.slice(index+1)]
            )
        }
        return   <li key={file.path} className={"py-2 rounded-8 flex justify-between items-center font-medium text-gray-50 text-14  px-4 bg-brown-50 border-1 border-brown-90"}>
            <div className={""}>
                <div>
                    {file.path}
                </div>
                <div className={"text-12  text-gray-90"}>
                    {file.size/1000} KB
                </div>
            </div>
            <div className={"cursor-pointer"} onClick={removeFile}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.19531 6.32422H19.3261L17.9654 20.5313H5.55602L4.19531 6.32422Z" stroke="#A6A6A6" stroke-width="1.44" stroke-linecap="square"/>
                    <path d="M16.0571 5.89088L15.0312 2.77344H8.48887L7.46289 5.89088" stroke="#A6A6A6" stroke-width="1.44" stroke-linecap="square"/>
                    <path d="M11.7617 11.043V15.817" stroke="#A6A6A6" stroke-width="1.44" stroke-linecap="square"/>
                </svg>
            </div>
        </li>

    });


    const [selectedDate, setSelectedDate] = useState();


    useEffect(()=>{
        if (selectedDate)
            setDataToSend((prev)=>({...prev, "event_date": format(selectedDate,"yyyy-MM-dd")}))
    },[selectedDate])

   const [isDayPickerOpened,setIsDayPickerOpened]=useState(false)
    const dayPickerWrapper = useRef();

    useOnClickOutside(dayPickerWrapper, () => setIsDayPickerOpened(false));
    const alert = useAlert()

    const queryClient = useQueryClient();
    const postMutation = useMutation({
        mutationFn:(d)=>axiosClient.post("/lcs-events",d),
        onSuccess:()=>{
            queryClient.invalidateQueries("/lcs-events")
            alert.success("Event added successfully!")
            setIsOpened(false)
            setDataToSend({
                "title":"",
                "description":"",
                "type":activeType==0?"event":"note",
                "file_ids":""
            })
            setAddedFiles([])
            setSelectedDate(null)
        }
    })

    const [btnLoading,setBtnLoading]=useState(false);

    const handleSubmit =async ()=>{
        setBtnLoading(true)
        const arr=[];
        for (const file of addedFiles) {
            await uploadSingleFile(file).then((res)=>arr.push(res))
        }
         postMutation.mutateAsync({
             "title":dataToSend.title,
             "description":dataToSend.description,
             "type":dataToSend.type,
             "event_date":dataToSend.event_date,
             "file_ids":arr
         })
             .then((res)=>console.log(res))
             .catch((e)=>console.log(e))
             .finally(()=>setBtnLoading(false))
    }
    return (
        <div className={classNames("absolute overflow-auto bg-brown-50 z-20 text-14 font-medium  hidden  md:flex flex-col  md:-right-[500px] top-0 transition-all duration-500 w-full md:w-[500px] py-6 px-8 h-full",isOpened && "!flex !right-0 shadow-black/60 shadow-[0_0_100vw_100vw]")}>
            <div className="first flex mb-3 justify-between items-center w-full">
                <div className="title flex gap-2 items-center md:text-24 text-18 font-semibold text-gray-50 ">
                    <div className={"md:hidden"} onClick={()=>setIsOpened(false)}>
                        <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.5 16L1.5 9L8.5 2" stroke="#D9D9D9" stroke-width="1.5" stroke-linecap="square"/>
                        </svg>
                    </div>
                    Add new to Calendar
                </div>
                <div className={"cursor-pointer hidden md:block"} onClick={()=>setIsOpened(false)}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 6L18 18" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
                        <path d="M18 6L6 18" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
                    </svg>
                </div>

            </div>
            <div className={"mt-4 mb-4"}>
                Select type
                <div className="tabs mt-3 flex gap-2">
                    <div onClick={()=>{setActiveType(0)}} className={classNames("bordered-tab cursor-pointer flex gap-2 items-center",activeType==0 && "!bg-brown-110")}>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.88721 7.29614H14.758" stroke="#D9D9D9" stroke-width="1.08" stroke-linecap="square"/>
                            <path d="M11.7778 9.89722H11.7842" stroke="#D9D9D9" stroke-width="1.08" stroke-linecap="square"/>
                            <path d="M8.82263 9.89722H8.82908" stroke="#D9D9D9" stroke-width="1.08" stroke-linecap="square"/>
                            <path d="M5.86108 9.89722H5.8675" stroke="#D9D9D9" stroke-width="1.08" stroke-linecap="square"/>
                            <path d="M11.7778 12.4856H11.7842" stroke="#D9D9D9" stroke-width="1.08" stroke-linecap="square"/>
                            <path d="M8.82263 12.4856H8.82908" stroke="#D9D9D9" stroke-width="1.08" stroke-linecap="square"/>
                            <path d="M5.86108 12.4856H5.8675" stroke="#D9D9D9" stroke-width="1.08" stroke-linecap="square"/>
                            <path d="M11.5129 2.36499V4.55666" stroke="#D9D9D9" stroke-width="1.08" stroke-linecap="square"/>
                            <path d="M6.13275 2.36499V4.55666" stroke="#D9D9D9" stroke-width="1.08" stroke-linecap="square"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8139 3.41675H2.82593V15.6851H14.8139V3.41675Z" stroke="#D9D9D9" stroke-width="1.08" stroke-linecap="square"/>
                        </svg>
                        Event
                    </div>
                    <div onClick={()=>{setActiveType(1)}} className={classNames("bordered-tab cursor-pointer flex gap-2 items-center",activeType==1 && "!bg-brown-110")}>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_8272_17402)">
                                <path d="M10.3269 12.0862H6.40778" stroke="#D9D9D9" stroke-width="1.08" stroke-linecap="square" stroke-linejoin="round"/>
                                <path d="M8.84275 9.36292H6.40704" stroke="#D9D9D9" stroke-width="1.08" stroke-linecap="square" stroke-linejoin="round"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8905 2.57064C10.749 2.42335 10.5537 2.34009 10.3495 2.34009H4.04431C3.6301 2.34009 3.29431 2.67587 3.29431 3.09009V14.9101C3.29431 15.3243 3.6301 15.6601 4.04431 15.6601H13.5958C14.01 15.6601 14.3458 15.3243 14.3458 14.9101V6.47101C14.3458 6.27737 14.2709 6.09123 14.1368 5.95156L10.8905 2.57064Z" stroke="#D9D9D9" stroke-width="1.08" stroke-linecap="square"/>
                                <path d="M10.3262 2.7395V6.5882H14.0033" stroke="#D9D9D9" stroke-width="1.08" stroke-linecap="square"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_8272_17402">
                                    <rect width="18" height="18" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                        Note
                    </div>
                </div>

                <div className={"mt-4"}>
                    <div className="input-box mb-4 flex flex-col gap-3">
                        <label className="label">Title (required)</label>
                        <input onChange={handleChange} value={dataToSend.title}  name={"title"} placeholder={"Type here"} type="text"/>
                    </div>
                    <div className="input-box flex flex-col gap-3">
                        <label className="label">Description</label>
                        <textarea onChange={handleChange} value={dataToSend.description} name={"description"} className={"h-[100px]"} placeholder={"Enter a description..."} type="text"/>
                    </div>
                </div>
                <div  className={"mt-4"}>
                    <div>
                        Pick date and time
                    </div>
                    <div  className={"relative cursor-pointer"} onClick={()=>setIsDayPickerOpened(true)}>
                        <div className={"rounded-8 mt-2 flex gap-2 border-brown-110 border-1 bg-brown-80 py-2 px-3"}>
                            <span>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.84956 9.72827H19.6773" stroke="#A6A6A6" stroke-width="1.7" stroke-linecap="square"/>
                                    <path d="M15.7037 13.1964H15.7122" stroke="#A6A6A6" stroke-width="1.7" stroke-linecap="square"/>
                                    <path d="M11.7635 13.1964H11.7721" stroke="#A6A6A6" stroke-width="1.7" stroke-linecap="square"/>
                                    <path d="M7.81481 13.1964H7.82337" stroke="#A6A6A6" stroke-width="1.7" stroke-linecap="square"/>
                                    <path d="M15.7037 16.6476H15.7122" stroke="#A6A6A6" stroke-width="1.7" stroke-linecap="square"/>
                                    <path d="M11.7635 16.6476H11.7721" stroke="#A6A6A6" stroke-width="1.7" stroke-linecap="square"/>
                                    <path d="M7.81481 16.6476H7.82337" stroke="#A6A6A6" stroke-width="1.7" stroke-linecap="square"/>
                                    <path d="M15.3506 3.15326V6.07548" stroke="#A6A6A6" stroke-width="1.7" stroke-linecap="square"/>
                                    <path d="M8.17705 3.15326V6.07548" stroke="#A6A6A6" stroke-width="1.7" stroke-linecap="square"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.752 5.55554C19.752 5.00326 19.3043 4.55554 18.752 4.55554H4.76799C4.21571 4.55554 3.76799 5.00326 3.76799 5.55554V19.9133C3.76799 20.4656 4.21571 20.9133 4.76799 20.9133H18.752C19.3043 20.9133 19.752 20.4656 19.752 19.9133V5.55554Z" stroke="#A6A6A6" stroke-width="1.7" stroke-linecap="square"/>
                                    </svg>
                            </span>
                            <span>
                                <div className={"text-12 text-gray-90 "}>
                                    Date
                                </div>
                                <div className={"text-14 text-gray-50"}>
                                  {selectedDate ?
                                        <div>
                                            {format(selectedDate, "E, MMM d, Y")}
                                        </div>
                                      :
                                      <div onClick={()=>setIsDayPickerOpened(true)} >
                                         Pick a Day
                                      </div>
                                  }
                                </div>
                            </span>
                        </div>
                        {isDayPickerOpened &&
                            <div ref={dayPickerWrapper} className={"absolute top-[110%] border-1 border-brown-110 bg-brown-80 rounded-8"}>
                                <DayPicker
                                    showOutsideDays
                                    mode="single"
                                    selected={selectedDate}
                                    onSelect={setSelectedDate}
                                />
                            </div>
                        }
                    </div>
                </div>
                <div className="mt-4">
                    <div className={"mb-2"}>
                        Upload file
                    </div>
                    <div {...getRootProps({className: 'upload-area   mb-4 dropzone'})}>
                        <input {...getInputProps()} />
                        <div className={"text-gray-90  w-full text-14 flex  flex-col  gap-2 font-medium "}>
                            <div className={"mt-1 flex gap-4 items-center"}>
                                <div>
                                    <svg width="32" height="32" viewBox="0 0 44 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14 28L22 20M22 20L30 28M22 20V38M38 29.4857C40.443 27.4681 42 24.4159 42 21C42 14.9249 37.0751 10 31 10C30.563 10 30.1541 9.77199 29.9322 9.39548C27.3241 4.96967 22.5089 2 17 2C8.71573 2 2 8.71573 2 17C2 21.1322 3.67089 24.8742 6.3739 27.5871" stroke="url(#paint0_linear_7559_68556)" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                                        <defs>
                                            <linearGradient id="paint0_linear_7559_68556" x1="22" y1="-18.3684" x2="22" y2="20" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="white"/>
                                                <stop offset="1" stop-color="#D9D9D9"/>
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                </div>
                                <div>
                                    <div className={"mb-1"}>
                                        <span>Click to upload</span>
                                    </div>
                                    {/*<div className={"text-12"}>*/}
                                    {/*    SVG, PNG, JPG or GIF (max. 800x400px)*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>

                    </div>
                    {files.length ?
                        <aside>
                            <h4 className={"mb-2"}>Files</h4>
                            <ul className={"flex flex-col gap-4 mb-6"}>{files}</ul>

                        </aside>
                        :""
                    }
                </div>
            </div>
            <div className={"btn-wrapper flex-col-reverse md:flex-row mt-auto flex gap-4 items-center"}>
                <button onClick={()=> {
                    setIsOpened(false)
                    setDataToSend({
                        "title":"",
                        "description":"",
                        "type":activeType==0?"event":"note",
                        "file_ids":""
                    })
                    setAddedFiles([])
                    setSelectedDate(null)
                }} className="btn w-full flex-grow-[1]">
                    Cancel
                </button>
                {/*disabled={!dataToSend.title || !dataToSend.description || !dataToSend.event_date}*/}
                <button onClick={handleSubmit} className="btn w-full md:w-full grey-50 flex-grow-[2]">
                    {btnLoading?
                        <div><CustomSpinner/></div>
                        :
                        "Add new event"
                    }
                </button>
            </div>
        </div>

    );
};

export default AdminSidebar;
