import React, {useState} from 'react';
import MobilePinnedMainHeader from "../../Sections/MobilePinnedMainHeader";
import {ActivityBox, CommentBox, NewCourseBox, NewVideoBox} from "./ActivityBoxes";
import movieIcon from "./Icons/movie-2-line 1.svg";
import profileIcon from "./Icons/Profile 1.svg";
import starIcon from "./Icons/Star 1.svg";
import UsdcIcon from "./Icons/USDC.svg";
import courseIcon from "./Icons/Courses.svg";
import classNames from "classnames";
import s from "./styles.module.scss"
import PageTitle from "../../Components/PageTitle/PageTitle";
import {useQuery} from "react-query";
import axiosClient from "../../Api/apiClient";
import {Suspense} from "react";
import ResponsivePagination from "react-responsive-pagination";
import RenderNotifsByType from "./RenderNotifsByType";
import {FullPageLoading} from "../../Components/LoadingPage";
import {ScrollContainer} from "react-indiana-drag-scroll";
import PinnedBox from "../../Sections/MobilePinnedMainHeader/PinnedBox";
import {MainThreadsName} from "../Chat/MainThreadsName";
const Activities = () => {
    PageTitle("Activities")


    const CategoriesName = ["Sessions","Courses","Mentors","Videos","Strategies","Chat"]
    const [activeTab,setActiveTab]=useState("all")
    const [page,setPage]=useState(1)

    const NotifsList=()=>{

        const filterTab = ()=>{
            switch (activeTab){
                case "Sessions":
                    return "&type[]=OWNER_NEW_SESSION&type[]=SESSION_REJECTED&type[]=OWNER_SESSION_REJECTED&type[]=OWNER_SESSION_ACCEPTED&type[]=SESSION_ACCEPTED&type[]=NEW_SESSION"
                    break;
                case "Courses":
                    return "&type[]=ADMIN_NEW_COURSE&type[]=NEW_COURSE"
                    break;
                case "Mentors":
                    return "&type[]=ADMIN_NEW_MENTOR&type[]=NEW_MENTOR"
                    break;
                case "Videos":
                    return "&type[]=ADMIN_NEW_VIDEO&type[]=NEW_VIDEO"
                    break;
                case "Strategies":
                    return "&type[]=NEW_STRATEGY&type[]=ADMIN_NEW_STRATEGY"
                    break;
                case "Chat":
                    return "&type[]=REPLY_MESSAGE"
                    break;
                case "all":
                    return ""
                    break;

            }
        }
        const {data}= useQuery({
            queryKey:['/activities',page,activeTab],
            queryFn:()=>{
                return axiosClient.get(`/activities?page=${page}${filterTab()}`)
            },
            cacheTime:0,
            staleTime:0
        })
        const perpage = data.data.resulting_data.meta.per_page
        const totalItems = data.data.resulting_data.meta.total
        const totalPages = Math.ceil(totalItems/perpage)

        const notifsArray = data.data.resulting_data.data


        return(
            <div className="main flex-grow overflow-auto  flex flex-col lg:px-8 lg:py-6 lg:gap-3 ">
                {
                    notifsArray.map(i=>
                            <RenderNotifsByType key={i.id} data={i}/>
                    )
                }
                <div className={"mt-auto"}>
                    <ResponsivePagination
                        current={page}
                        total={totalPages}
                        onPageChange={setPage}
                    />
                </div>
            </div>

        )
    }
    return (
        <div className={classNames("h-full flex flex-col overflow-hidden",s.container)}>
                <MobilePinnedMainHeader title={"Activities"}/>
                <ScrollContainer className={"tabs border-b-2 h-fit shrink-0 flex flex-nowrap border-brown-90 gap-2 px-8 py-3"}>
                    <button onClick={()=>{setActiveTab("all")}}  className={classNames("tab ",activeTab=="all" && "active")}>All</button>
                    {CategoriesName.map((item)=><button onClick={()=>{setActiveTab(item)}} className={classNames("tab",activeTab==item && "active")}>{item}</button>)}
                </ScrollContainer>
                <Suspense fallback={<FullPageLoading/>}>
                    <NotifsList/>
                </Suspense>
        </div>
    );
};

export default Activities;
