import React, {useState,Suspense} from 'react';
import classNames from "classnames";
import s from "./styles.module.scss";
import MobilePinnedMainHeader from "../../Sections/MobilePinnedMainHeader";
import PopupContainer from "../../Sections/PopupContainer";
import BookSessionBox from "../Mentors/MentorBox/BookSessionBox";
import {useLocation} from "react-router-dom";
import {RoutesSubscriptionText} from "./RoutesSubscriptionText";
import {SubscriptionBox} from "./SubscriptionBox";
import {useQuery} from "react-query";
import PageTitle from "../../Components/PageTitle/PageTitle";

const Subscription = () => {
    PageTitle("Subscription")

    const [isStandardModeActive,setIsStandardModeActive]=useState(true)
    const location = useLocation()


    const renderByPath =(isDesc)=>{
        switch (location.pathname.toLowerCase()){
            case "/session":
                if (isDesc)
                    return RoutesSubscriptionText.sessions;
                else
                    return "Online sessions";
            case "/courses":
                if (isDesc)
                    return RoutesSubscriptionText.courses;
                else
                    return "Online courses";
            case "/mentors":
                if (isDesc)
                    return RoutesSubscriptionText.mentors;
                else
                    return "Online mentors";
            case "/videos":
                if (isDesc)
                    return RoutesSubscriptionText.videos;
                else
                    return "Online videos";
            case "/faq":
                if (isDesc)
                    return RoutesSubscriptionText.faq;
                else
                    return "FAQ and Resources";
            case "/calendar-and-ideas":
                if (isDesc)
                    return RoutesSubscriptionText.calendar;
                else
                    return "Calendar and Ideas";
            case "/news-and-trends":
                if (isDesc)
                    return RoutesSubscriptionText.news;
                else
                    return "News and Trends";
            case "/cryptocurrencies":
                if (isDesc)
                    return RoutesSubscriptionText.cryptocurrencies;
                else
                    return "Cryptocurrencies";
            case "/trading-strategies":
                if (isDesc)
                    return RoutesSubscriptionText.trading;
                else
                    return "Trading Strategies";


        }
    }

    const PlansList =()=>{
        const {data} = useQuery({
            queryKey:['/payment/products']
        })
        const product = isStandardModeActive ? data.resulting_data[1] : data.resulting_data[0]
        console.log(product)
        return(
            <>
                <div className={"flex flex-col md:flex-row gap-5 justify-between  md:items-center"}>
                    <div className="main-title text-24 font-semibold text-gray-50">
                        Choose your plans today
                    </div>
                    <div className={"tabs p-2  flex bg-brown-40 rounded-8 font-semibold"}>
                        <button onClick={()=>setIsStandardModeActive(true)} className={classNames("tab",isStandardModeActive && "active")}>
                            Standard
                        </button>
                        <button onClick={()=>setIsStandardModeActive(false)} className={classNames("tab",!isStandardModeActive && "active")}>
                            Premium
                        </button>
                    </div>
                </div>
                <div className={" "}>
                    <Suspense fallback={"loading..."}>
                        <ListOfBoxes product_id={product.id} features={product.features}/>
                    </Suspense>
                </div>
            </>
        )
    }

    const ListOfBoxes=({product_id,features})=>{
            const {data} = useQuery({
                queryKey:[`/payment/products/${product_id}/plans`],
            })
            const plans = data.resulting_data
        return (
            <div className={"gap-4 mt-4 md:mt-8  grid md:grid-cols-3"}>
                {plans.map(item=>
                    <SubscriptionBox key={item.id} data={item} features={features}/>
                )}
            </div>
        )
    }

    return (
        <div className={classNames(s.container,"h-full  flex flex-col overflow-hidden")}>
            <MobilePinnedMainHeader title={"subscription model"}/>
            <div className={" flex-grow flex font-medium flex-col overflow-auto pad-layout py-9"}>
                <div className="warning-box p-6">
                    <div className="w-title text-18">
                        What to expect in {renderByPath(false)}?
                    </div>
                    <p className={"opacity-70 mt-3"}>
                        {
                            renderByPath(true)
                        }
                    </p>
                </div>

                <div className={"flex-grow flex flex-col mt-8 md:mt-12  pr-2"}>
                    <Suspense>
                       <PlansList/>
                    </Suspense>
                </div>
            </div>

            {/*<PopupContainer>*/}
            {/*    <div className={"w-full h-full  md:items-center justify-center flex max-h-[90vh] overflow-hidden"}>*/}
            {/*        <div className={"subscription_modal relative flex justify-center items-center flex-col"}>*/}
            {/*            <div className="absolute right-6 top-6">*/}
            {/*                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
            {/*                    <path d="M6 6L18 18" stroke="#A6A6A6" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>*/}
            {/*                    <path d="M18 6L6 18" stroke="#A6A6A6" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>*/}
            {/*                </svg>*/}
            {/*            </div>*/}
            {/*            <svg width="99" height="98" viewBox="0 0 99 98" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
            {/*                <path d="M43.7579 68.1939C46.6755 67.7692 49.6453 68.198 52.591 68.1298C59.424 67.9656 64.7781 62.1906 69.9799 58.271C72.6369 56.2714 76.3601 56.536 78.7084 58.8961C81.3177 61.5131 81.3177 65.7614 78.7084 68.3785C73.0897 74.0171 68.1285 79.2511 60.5139 82.2124C49.9499 86.3202 40.1472 84.3247 29.4469 82.2124C25.307 81.3912 21.4918 81.343 17.2998 81.343" stroke="white" stroke-width="6.125" stroke-linecap="round" stroke-linejoin="round"/>*/}
            {/*                <path d="M43.7502 68.0279H49.5171C53.6367 68.0279 56.963 65.0663 56.963 60.9466C56.963 57.5239 54.6469 54.7869 51.3284 53.9535C46.5795 52.7632 41.8384 51.8293 36.881 51.9453C29.0221 52.138 23.5276 56.5141 17.2998 60.6861" stroke="white" stroke-width="6.125" stroke-linecap="round" stroke-linejoin="round"/>*/}
            {/*                <path opacity="0.4" d="M63.6923 15.4766L64.2648 17.0236C66.4232 22.8568 71.0223 27.456 76.8558 29.6145L78.4025 30.1869L76.8558 30.7594C71.0223 32.9179 66.4232 37.517 64.2648 43.3503L63.6923 44.8975L63.1198 43.3503C60.9614 37.517 56.3623 32.9179 50.5289 30.7594L48.9817 30.1869L50.5289 29.6145C56.3623 27.456 60.9614 22.8568 63.1198 17.0236L63.6923 15.4766Z" stroke="white" stroke-width="6.125" stroke-linecap="round" stroke-linejoin="round"/>*/}
            {/*                <path opacity="0.4" d="M33.5954 9.56055C34.8349 13.6334 38.0223 16.8209 42.095 18.0606C38.0223 19.3002 34.8349 22.4877 33.5954 26.5606C32.3558 22.4877 29.1684 19.3002 25.0957 18.0606C29.1684 16.8209 32.3558 13.6334 33.5954 9.56055Z" stroke="white" stroke-width="6.125" stroke-linecap="round" stroke-linejoin="round"/>*/}
            {/*            </svg>*/}
            {/*            <div className={"text-48 font-semibold mb-5"}>*/}
            {/*                Thank you*/}
            {/*            </div>*/}
            {/*            <div className={"text-gray-90 mb-5 text-14 font-medium max-w-[370px] text-center"}>*/}
            {/*                Thank you for submitting your payment. We are currently reviewing your request and will provide an update soon.*/}
            {/*            </div>*/}
            {/*            <button className="btn grey-50 w-[200px]">*/}
            {/*                Activities*/}
            {/*            </button>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</PopupContainer>*/}

        </div>

    );
};

export default Subscription;
