import s from "./styles.module.scss";
import React from "react";
import {useMutation} from "react-query";
import axios from "axios";
import axiosClient from "../../Api/apiClient";
import { useAlert } from 'react-alert'
import {useNavigate} from "react-router-dom";
import BtnTextForMutation from "../../Components/BtnTextForMutation";

export const SubscriptionBox = ({data,features})=>{
    const alert = useAlert()
    const navigate = useNavigate();
    const buyMutation = useMutation({
        mutationFn:()=>axiosClient.post('/payment/subscribe',{
            "price_id":data.id
        }),
        onSuccess:(res)=>{
            window.open(res.data.resulting_data.url, '_blank');
        },
        onError:(err)=>{
            alert.show(err.response.data.error.subscribed)
        }
    })
    const buyPlan =()=>{
        buyMutation.mutate()
    }
    return(
        <div className={s.subscription_box}>
            <div className="first-line">
                    <span>
                        {data.interval_count} {data.interval}
                    </span>
                <span className={"bg-gray-50  text-12 text-brown-90 p-1 rounded-30 ml-2"}>
                   3-Day Trial
                </span>
            </div>
            <div className="line my-3  md:my-6 bg-brown-50 w-full h-[1px]"/>
            <span className={"text-14 text-gray-90 mb-2"}>
                    Price
                </span>
            <div className={"font-bold flex uppercase items-center flex-wrap gap-2 text-32"}>
                {`${data.amount} ${data.currency}`}
                {/*<span className={"text-14 text-gray-90"}>*/}
                {/*        ~ {data.currency}*/}
                {/*    </span>*/}
            </div>
            <div className="line my-4 md:my-10 bg-brown-50 w-full h-[1px]"/>
            <ul>
                {features.map(i=>{
                    return(
                        <li className="list-item">{i}</li>

                    )
                })}
            </ul>
            <button onClick={buyPlan} className="btn grey-50 w-full mt-4">
                <BtnTextForMutation mutation={buyMutation} defaultText={"Choose plan"}/>
            </button>
        </div>
    )
}
