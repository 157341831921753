import React, {useState} from 'react';
import MobilePinnedMainHeader from "../../../Sections/MobilePinnedMainHeader";
import s from "./styles.module.scss"
import PageTitle from "../../../Components/PageTitle/PageTitle";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {useGetProfile} from "../../../Hooks/useGetProfile";
import BtnTextForMutation from "../../../Components/BtnTextForMutation";
import axiosClient from "../../../Api/apiClient";
import {useAlert} from "react-alert";
import {useNavigate} from "react-router-dom";
const MentorsEdit = () => {
    PageTitle("Edit")

    const me = useGetProfile()
    const [aboutValue,setAboutValue]=useState(me.about_me)
    const queryClient  = useQueryClient()
    const alert = useAlert()
    const navigate = useNavigate()
    const mutation =useMutation({
        mutationFn: (data)=>axiosClient.put(`/user/profile`, data),
        onSuccess:()=>{
            alert.success("Your info updated successfully!")
            queryClient.invalidateQueries("/auth/me")
            setTimeout(()=>{
                navigate("/mentors")
            },2000)
        }
    })
    const handleSubmit=()=>{
        mutation.mutate({
            name: me.name,
            phone_number: me.phone_number,
            occupation: me.occupation,
            bio: me.bio,
            about_me:aboutValue
        })
    }


    const [textLetters,setTextLetters]=useState(0);
    const handleChange=(e)=>{
        setAboutValue(e.target.value)
        setTextLetters(e.target.value.length)
    }
    return (
        <div className={`${s.container} h-full flex flex-col overflow-hidden`}>
            <MobilePinnedMainHeader hasBackBtn={true} title={"Mentors edit "}/>
            <div className={"flex-grow flex flex-col p-8 overflow-auto"}>
                {/*<div className="btn dark w-fit ml-auto">Set Availability</div>*/}
                <div className="edit-mentor-box mt-12 py-10 px-6">

                    <div className="profile mb-8 sm:mb-12 flex flex-col sm:flex-row gap-6 md:items-center">
                        <div className="avatar w-10 h-10 sm:w-[88px] sm:h-[88px] rounded-full bg-white overflow-hidden shrink-0">
                            <img src={me.profile_image_url || "defaultAvatar.png"} className={"w-full h-full object-cover"} alt=""/>
                        </div>
                        <div className={"flex flex-col gap-2"}>
                            <div className="name text-18 font-medium">
                                {me.name}
                            </div>
                            <div className="role text-gray-50">
                                {me.occupation}
                            </div>
                            {/*<div className={"text-12 text-gray-50"}>*/}
                            {/*    5 years experience*/}
                            {/*</div>*/}
                        </div>

                    </div>
                    <div className={"text-box"}>
                        <div className={"text-gray-50"}>
                            About
                        </div>
                        <div className={"relative mt-4 mb-10"}>
                            <textarea value={aboutValue} maxLength={"300"}  onChange={handleChange}  className={"text-gray-50 w-full h-[200px] "}>
                             </textarea>
                            <div className={"absolute right-2 bottom-4 flex items-center text-12 text-gray-90  z-20"}>
                                {textLetters}/300
                            </div>
                        </div>

                    </div>
                    <button onClick={handleSubmit} className="btn grey-50  w-[200px] ml-auto mt-8">
                        <BtnTextForMutation defaultText={"Save"} successText={"Saved!"} mutation={mutation}/>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default MentorsEdit;
