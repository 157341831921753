import React, {Suspense, useState} from 'react';
import {useMutation, useQuery} from "react-query";
import axiosClient from "../../../Api/apiClient";
import classNames from "classnames";
import s from "../Create/styles.module.scss";
import MobilePinnedMainHeader from "../../../Sections/MobilePinnedMainHeader";
import CustomSpinner from "../../../Utility/CustomSpinner";
import {Link, useLocation} from "react-router-dom";
import {FullPageLoading} from "../../../Components/LoadingPage";
import {useEditGetAndPost} from "../../../Hooks/useEditGetAndPost";
import BtnTextForMutation from "../../../Components/BtnTextForMutation";
import PageTitle from "../../../Components/PageTitle/PageTitle";

const CryptoCurrencyEdit = () => {

    PageTitle("Edit")


    const PageData =()=>{
        const {fetchedData,handleSubmitEdit,postMutation} = useEditGetAndPost("/bundles","/cryptocurrencies")


        const [searchedCoin,setSearchedCoin]=useState("")
        const [titleValue,setTitleValue]=useState(fetchedData.title)
        const [coinsAdded,setCoinsAdded]=useState(fetchedData.coins)

        const AddedCoinItem =({title,desc,id,img})=>{
            const removeCoin=()=>{
                setCoinsAdded(coinsAdded.filter((i,index)=>(i.id !== id)))
            }
            return(
                <div className={"coin bg-brown-50 px-2 flex justify-between items-center py-5 border-b-1 border-brown-110 "}>
                    <div className={"flex gap-2 items-center"}>
                        <div className="sqr rounded-8 p-2 bg-brown-110 items-center flex justify-center w-fit mr-3">
                            <div className="circle w-8 h-8 rounded-full bg-green overflow-hidden">
                                <img src={img} className={"w-full h-full object-cover"} alt=""/>
                            </div>
                        </div>
                        <div className={"text-gray-50 mr-2 font-medium"}>
                            {title}
                        </div>
                        <div className={"text-12 text-gray-90 px-2 py-1  rounded-30 bg-brown-50"}>
                            {desc}
                        </div>
                    </div>
                    <div className={"flex gap-4 items-center"}>
                        <div onClick={removeCoin} className="remove cursor-pointer">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.5 4.5L13.5 13.5" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
                                <path d="M13.5 4.5L4.5 13.5" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
                            </svg>
                        </div>
                        <div className="move">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.76466 16.7617C8.06966 16.7617 7.50366 17.3267 7.50366 18.0227C7.50366 18.7177 8.06966 19.2827 8.76466 19.2827C9.45966 19.2827 10.0257 18.7177 10.0257 18.0227C10.0257 17.3267 9.45966 16.7617 8.76466 16.7617Z" fill="#A6A6A6"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.76466 10.7402C8.06966 10.7402 7.50366 11.3052 7.50366 12.0012C7.50366 12.6962 8.06966 13.2612 8.76466 13.2612C9.45966 13.2612 10.0257 12.6962 10.0257 12.0012C10.0257 11.3052 9.45966 10.7402 8.76466 10.7402Z" fill="#A6A6A6"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.76466 4.71875C8.06966 4.71875 7.50366 5.28375 7.50366 5.97975C7.50366 6.67475 8.06966 7.23975 8.76466 7.23975C9.45966 7.23975 10.0257 6.67475 10.0257 5.97975C10.0257 5.28375 9.45966 4.71875 8.76466 4.71875Z" fill="#A6A6A6"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.2354 16.7617C14.5404 16.7617 13.9744 17.3267 13.9744 18.0227C13.9744 18.7177 14.5404 19.2827 15.2354 19.2827C15.9304 19.2827 16.4964 18.7177 16.4964 18.0227C16.4964 17.3267 15.9304 16.7617 15.2354 16.7617Z" fill="#A6A6A6"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.2354 10.7402C14.5404 10.7402 13.9744 11.3052 13.9744 12.0012C13.9744 12.6962 14.5404 13.2612 15.2354 13.2612C15.9304 13.2612 16.4964 12.6962 16.4964 12.0012C16.4964 11.3052 15.9304 10.7402 15.2354 10.7402Z" fill="#A6A6A6"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.2354 7.23975C15.9304 7.23975 16.4964 6.67475 16.4964 5.97875C16.4964 5.28375 15.9304 4.71875 15.2354 4.71875C14.5404 4.71875 13.9744 5.28375 13.9744 5.97875C13.9744 6.67475 14.5404 7.23975 15.2354 7.23975Z" fill="#A6A6A6"/>
                            </svg>
                        </div>
                    </div>

                </div>
            )
        }
        const CoinsList =()=>{
            const { data  } = useQuery({
                queryKey:"/coins",
            })
            const CoinItem =({title,desc,id,img})=>{
                const AddToCoins =()=>{
                    if (coinsAdded.filter((i) =>i.id === id).length === 0){
                        setCoinsAdded(prev=>[...prev,data.resulting_data.filter((item)=>item.id === id)[0]])
                    }
                }
                return(
                    <div className={"coin flex justify-between items-center py-5 border-b-1 border-brown-110 "}>
                        <div className={"flex gap-2 items-center"}>
                            <div className="circle w-8 h-8 rounded-full bg-green overflow-hidden mr-3">
                                <img src={img} className={"w-full h-full object-cover"} alt=""/>
                            </div>
                            <div className={"text-gray-50 mr-2 font-medium"}>
                                {title}
                            </div>
                            <div className={"text-12 text-gray-90 px-2 py-1  rounded-30 bg-brown-50"}>
                                {desc}
                            </div>
                        </div>
                        <div onClick={AddToCoins} className={"flex gap-2 items-center px-3 py-2 bg hover:bg-gray-50 rounded-8 border-1 border-brown-90 font-semibold text-gray-90 hover:text-brown-40"}>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 1V11" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                                <path d="M11 6H1" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                            </svg>
                            Add
                        </div>

                    </div>
                )
            }
            return(
                <div>
                    {data.resulting_data.filter(coin=>coin?.title?.toLowerCase().includes(searchedCoin?.toLowerCase()))?.map((coin,index)=>
                        <div key={index}>
                            <CoinItem id={coin.id} img={coin.url} title={coin.title} desc={coin.code}/>
                        </div>
                    )}
                </div>
            )
        }


        const handleSubmit =()=>{
            handleSubmitEdit({
                "title":titleValue,
                "coin_ids":coinsAdded.map(i=>i.id)
            })
        }
        return(
            <div className="flex-grow overflow-hidden flex flex-col  p-8">
                <div className="title-line mb-6">
                    <h3 className={"text-24 font-semibold mb-2"}>Traders choice</h3>
                    <div className={"font-medium text-gray-90"}>
                        This channel will be updated monthly with new investing indexes
                    </div>
                </div>
                <div className="box flex-grow overflow-hidden flex gap-10 p-8 pr-5 pb-0">
                    <div className="left w-[45%] h-full flex flex-col">
                        <div className={"text-18 text-gray-50 mb-8 font-medium"}>
                            Information about your offers
                        </div>
                        <div className="input-box mb-10 flex flex-col gap-3">
                            <label className="label">Title (required)</label>
                            <input value={titleValue} onInput={(e)=>{setTitleValue(e.target.value)}} placeholder={"Type here"} type="text"/>
                        </div>
                        <div className={"coins-wrapper flex-grow flex p-2 overflow-hidden mb-10 "}>
                            {coinsAdded.length === 0 ?
                                <div className="empty h-full w-full justify-center p-6 gap-3 text-gray-90 font-medium items-center flex flex-col">
                                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M42 24C42 14.0582 33.9418 6 24 6C14.0582 6 6 14.0582 6 24C6 33.9398 14.0582 42 24 42C33.9418 42 42 33.9398 42 24Z" stroke="#A6A6A6" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M23.9922 16.6133V25.2124M23.9998 31.4836V31.3882" stroke="#A6A6A6" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    <div className={"max-w-[300px] text-center"}>
                                        Please choose the coins that you would like to post.
                                    </div>
                                </div>
                                :
                                <div className="added-coins overflow-auto flex-grow px-4 ">
                                    {/*<ReactSortable*/}
                                    {/*    animation={200}*/}
                                    {/*    delayOnTouchStart={true}*/}
                                    {/*    delay={2}*/}
                                    {/*    multiDrag*/}
                                    {/*    list={coinsAdded} setList={setCoinsAdded}*/}
                                    {/*>*/}

                                    {coinsAdded?.map((item,index)=>{
                                        return(
                                            <div key={item.index}>
                                                <AddedCoinItem img={item.url} title={item.title} desc={item.code} id={item.id}/>
                                            </div>
                                        )
                                    })}
                                    {/*</ReactSortable>*/}
                                </div>
                            }
                        </div>
                    </div>
                    <div className="right flex overflow-hidden flex-col flex-grow">
                        <div className="first-line mb-8 pr-3  flex justify-between font-medium">
                            <span className={"text-18 text-gray-50"}>Select coin</span>
                            <span className={"text-gray-90"}>
                                {coinsAdded.length} coin added
                            </span>
                        </div>
                        <div className={"flex pr-3 w-full relative"}>
                            <div className="icon absolute my-auto bottom-0 top-0 left-4 h-fit">
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.31131 14.0511C11.5701 14.0511 14.2119 11.4093 14.2119 8.15054C14.2119 4.89176 11.5701 2.25 8.31131 2.25C5.05253 2.25 2.41077 4.89176 2.41077 8.15054C2.41077 11.4093 5.05253 14.0511 8.31131 14.0511Z" stroke="#A6A6A6" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M12.4252 12.5938L15.5893 15.7496" stroke="#A6A6A6" stroke-width="1.7" stroke-linecap="square" stroke-linejoin="round"/>
                                </svg>

                            </div>
                            <input value={searchedCoin} onInput={(e)=>{setSearchedCoin(e.target.value)}} className={"w-full pl-12"} placeholder={"Search in coins"} type="text"/>
                        </div>
                        <div className="coins flex flex-col flex-grow mt-10 overflow-auto pr-3 ">
                            <Suspense fallback={"loading"}>
                                <CoinsList/>
                            </Suspense>
                        </div>
                    </div>
                </div>
                <div className={"flex-col-reverse mt-8 w-full lg:w-fit lg:flex-row flex gap-4 ml-auto"}>
                    <Link to={"/Cryptocurrencies"} className="btn w-full lg:w-[170px]">Cansel</Link>
                    <button onClick={handleSubmit} className="btn grey-50 w-full lg:w-[170px]">
                       <BtnTextForMutation defaultText={"Publish"} successText={"Published!"} mutation={postMutation} spinnerSize={10}/>
                    </button>
                </div>
            </div>
        )
    }
    return (
        <div className={classNames(s.container,"flex flex-col h-full")}>
            <MobilePinnedMainHeader title={"Edit your bundle"} hasBackBtn/>
            <Suspense fallback={<FullPageLoading/>}>
                    <PageData/>
            </Suspense>
        </div>
    );
};

export default CryptoCurrencyEdit;
