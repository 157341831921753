import React, {useEffect, useState} from 'react';
import EventBox from "../EventBox";
import {useQuery} from "react-query";
import {format, parseISO} from "date-fns";
import MoreBtn from "../../../Sections/MoreBtn";
import {useUpdatePost} from "../../../Hooks/useUpdatePost";
import img from "./Frame 46492.png"
import axiosClient from "../../../Api/apiClient";
import {useGetProfile} from "../../../Hooks/useGetProfile";
import AdminCanView from "../../../Components/ViewLimit/AdminCanView";
const EventsContainer = ({selectedDate}) => {
    const [selectedEventId,setSelectedEventId]=useState(null)
    const [selectedSessionId,setSelectedSessionId]=useState(null)

    useEffect(()=>{
        if (selectedEventId){
            setSelectedSessionId(null)
        }
    },[selectedEventId])
    useEffect(()=>{
        if (selectedSession){
            setSelectedEventId(null)
        }
    },[selectedSessionId])
    useEffect(()=>{
        setSelectedSessionId(null)
        setSelectedEventId(null)

    },[selectedDate])


    const File =({data})=>{
        return(
            <a href={data.url} target={"_blank"} className={"flex justify-between w-full hover:bg-brown-40 hover:border-brown-100 items-center rounded-8 border-brown-90 bg-brown-80 py-2.5 px-4"}>
                <div>
                    <div className={"text-14 text-gray-50 font-medium"}>
                        {data.name}
                    </div>
                    {/*<div className={"text-12 text-gray-90"}>*/}
                    {/*    200 KB*/}
                    {/*</div>*/}
                </div>
                <div>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.88086 20.8811H20.6409V3.12109H2.88086V20.8811Z" stroke="#A6A6A6" stroke-width="1.44" stroke-linecap="round"/>
                        <path d="M11.7598 15.2054V8.07812" stroke="#A6A6A6" stroke-width="1.44" stroke-linecap="square"/>
                        <path d="M15.3583 12.3086L11.7602 15.922L8.16211 12.3086" stroke="#A6A6A6" stroke-width="1.44" stroke-linecap="square"/>
                    </svg>
                </div>
            </a>
        )
    }

    const {data}=useQuery({
        queryKey:["/lcs-events",selectedDate],
        queryFn :()=>axiosClient.get(`/lcs-events?start_date=${selectedDate}&end_date=${selectedDate}`)
    })
    const events = data.data.resulting_data?.data

    const nextDay =new Date( new Date(selectedDate).getTime() + 86400000)
    const prevDay = new Date(new  Date(selectedDate).getTime() - 86400000)

    const sessionData =useQuery({
        queryKey:["/sessions",prevDay,nextDay],
        queryFn :()=>axiosClient.get(`/sessions?start_date=${format(prevDay,"yyyy-LL-dd")}&end_date=${format(nextDay,"yyyy-LL-dd")}`)
    })
    const sessions = [...sessionData.data.data.resulting_data?.mentoring.data, ...sessionData.data.data.resulting_data?.requested.data]

    const selectedEvent = events?.filter((e)=>e.id == selectedEventId)[0]
    const selectedSession = sessions?.filter((e)=>e.id == selectedSessionId)[0]


    const {handleDelete,deleteMutation} = useUpdatePost(selectedEventId,"/lcs-events")
    const me = useGetProfile()
    const isMine = me.id == selectedEvent?.owner?.id
    const deleteFunc =async ()=>{
        handleDelete(()=>{setSelectedEventId(null)})
    }

    const time = selectedSession?.session_date?.split(" ")[1].slice(0,10)
    const utcDate = selectedSession?.session_date?.split(" ")[0]+"T"+time+"Z"
    const localDate =selectedSession? parseISO(`${format(utcDate,"yyyy-MM-dd HH:mm")}`) : ""


    return (
        <>
            <div className={"mb-6 flex gap-2 h-20 items-center cursor-pointer"} onClick={()=>{
                setSelectedEventId(null)
                setSelectedSessionId(null)
            }}>
                {(selectedEventId || selectedSessionId) &&
                    <button >
                        <svg className={"cursor-pointer"} width="32" height="32" viewBox="0 0 32 32" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M20.9999 27.2186L9.78125 15.9999L20.9999 4.78125L22.8852 6.66658L13.5519 15.9999L22.8852 25.3333L20.9999 27.2186Z"
                                  fill="#A6A6A6"/>
                        </svg>
                    </button>
                }
                <div className="title">
                    Calendar
                </div>
            </div>
            {
                selectedEventId || selectedSessionId?
                    <div className={"overflow-auto relative pr-1 "}>
                        <AdminCanView>
                            <div className={"absolute right-4 top-0"}>
                                <MoreBtn lowerHight options={[
                                    {name: "Delete", fn: deleteFunc, mutation: deleteMutation,isHidden:!(isMine||me.isOwner)},
                                ]}/>
                            </div>
                        </AdminCanView>
                        <div className={"text-14 text-gray-90 capitalize"}>
                            {selectedEvent?.type}
                            {selectedSession?.type}
                        </div>
                        <div className={"mt-6 flex flex-col md:flex-row gap-4 md:items-center"}>
                            <div className={"flex flex-col py-3 mr-4  flex-shrink-0 !bg-brown-50 w-fit rounded-[6px] !px-6 "}>
                                <span className={"mb-2"}>
                                      {selectedEvent && format(new Date(selectedEvent.event_date), 'E')}
                                      {selectedSession && format(new Date(localDate), ' E')}
                                </span>
                                <span className={"text-24 font-semibold "}>
                                      {selectedEvent && format(new Date(selectedEvent.event_date), 'd')}
                                      {selectedSession && format(new Date(localDate), 'HH:mm bbbb')}
                                </span>
                            </div>
                            <div className={"text-24 font-semibold"}>
                                {selectedEvent?.title}
                                {selectedSession?.title}
                            </div>
                        </div>
                        <div className={"w-fit border-1 border-brown-110 mt-7 text-gray-90 rounded-[4px] py-1 px-2 bg-brown-80"}>
                            {selectedEvent && format(new Date(selectedEvent.event_date), 'yyyy dd MMMM')}
                            {selectedSession && format(new Date(localDate), 'yyyy dd MMMM')}
                        </div>
                        <div className={"mt-4 text-gray-50 text-14"}>
                            {selectedEvent?.description}
                            {selectedSession?.description}
                        </div>
                        {selectedSession &&
                            <div className={"0 flex w-full justify-between md:items-end"}>
                                <div>

                                </div>
                                <a href={selectedSession?.link} className={"mt-4 w-full  md:w-fit cursor-pointer btn grey-50  text-14"}>
                                    Join meeting
                                </a>
                            </div>
                        }
                        <div className={"my-4 bg-brown-100 h-[1px] w-full"}>
                        </div>
                        <div className={"flex mb-6 flex-col gap-4"}>
                            {selectedEvent?.attachments.map(i => (
                                <File data={i} key={i.id}/>
                            ))}
                            {selectedSession?.attachments.map(i => (
                                <File data={i} key={i.id}/>
                            ))}
                        </div>


                    </div>
                    :
                    <div className={"flex flex-col pr-1 pb-4 overflow-auto flex-grow gap-2"}>
                        {
                            [...events].length==0 && [...sessions].length==0 ?
                                <div className={"text-gray-90 flex items-center justify-center flex-col h-full gap-4 font-medium text-18 text-center"}>
                                    <img src={img} alt=""/>
                                    <div className={"max-w-[200px]"}>You have no upcoming
                                        events.</div>
                                </div>
                                :
                                <>

                                {
                                    sessions.map((i)=><button className={"text-left"} key={i.id} onClick={() => setSelectedSessionId(i.id)}>
                                        <EventBox isMeet={true}  id={i.id} title={i.title} desc={i.description} date={i.session_date} selectedDate={selectedDate} />
                                    </button>)
                                }
                                {
                                    events.map((i)=><button className={"text-left"} key={i.id} onClick={() => setSelectedEventId(i.id)}>
                                        <EventBox type={i.type} id={i.id} title={i.title} desc={i.description} date={i.event_date}/>
                                    </button>)
                                }
                                </>


                        }

                    </div>
            }
        </>
    );
};

export default EventsContainer;
