import React, {useRef} from 'react';
import s from "./styles.module.scss"
import classNames from "classnames";
import ProfileHolder from "../../ProfileHolder";
import MoreLink from "./MoreLink";
import useOnClickOutside from "../../../../Hooks/useOnClickOutside";
const MoreOptions = ({setMoreOpen,isMoreOpen}) => {
    const moreHolderRef = useRef()
    useOnClickOutside(moreHolderRef,()=>setMoreOpen(false))

    return (
        <div ref={moreHolderRef} className={classNames("absolute w-full z-40 left-0  bottom-full  ",s.container,
            isMoreOpen? "translate-y-full":""
        )}>
            <div className={"mx-auto w-fit mb-4"}>
                <svg width="108" height="4" viewBox="0 0 108 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 2H106" stroke="#555555" stroke-width="4" stroke-linecap="round"/>
                </svg>

            </div>
            <ProfileHolder/>
            <ul className={"flex mt-6 flex-col gap-1"}>
                <MoreLink href={"/courses"} title={"Courses"}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.1818 2.2591V15.6418C18.1819 15.73 18.1564 15.8164 18.1083 15.8903C18.0602 15.9642 17.9916 16.0226 17.9109 16.0582L10 19.5591L2.08909 16.0591C2.00812 16.0233 1.93936 15.9647 1.89123 15.8904C1.84311 15.8162 1.81772 15.7294 1.81818 15.6409V2.2591H0V0.440918H20V2.2591H18.1818ZM3.63636 2.2591V14.7546L10 17.5727L16.3636 14.7546V2.2591H3.63636ZM6.36364 5.89546H13.6364V7.71365H6.36364V5.89546ZM6.36364 9.53183H13.6364V11.35H6.36364V9.53183Z" fill="#A6A6A6"/>
                    </svg>
                </MoreLink>

                <MoreLink href={"/mentors"} title={"Mentors"}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.55 15.8292L16.716 15.9962L16.8839 15.8292C17.081 15.6321 17.315 15.4758 17.5726 15.3691C17.8301 15.2624 18.1061 15.2075 18.3849 15.2075C18.6636 15.2075 18.9397 15.2624 19.1972 15.3691C19.4547 15.4758 19.6887 15.6321 19.8858 15.8292C20.0829 16.0264 20.2393 16.2604 20.346 16.5179C20.4526 16.7754 20.5075 17.0514 20.5075 17.3302C20.5075 17.6089 20.4526 17.885 20.346 18.1425C20.2393 18.4 20.0829 18.634 19.8858 18.8311L16.716 22L13.5481 18.8311C13.15 18.4331 12.9264 17.8932 12.9264 17.3302C12.9264 16.7672 13.15 16.2273 13.5481 15.8292C13.9462 15.4312 14.4861 15.2075 15.049 15.2075C15.612 15.2075 16.1519 15.4312 16.55 15.8292ZM11.0396 14.2642V16.1509C9.53838 16.1509 8.09864 16.7473 7.03711 17.8088C5.97558 18.8704 5.37922 20.3101 5.37922 21.8113H3.49243C3.49245 19.8505 4.25558 17.9667 5.62023 16.5588C6.98489 15.1508 8.84393 14.3292 10.8038 14.2679L11.0396 14.2642ZM11.0396 2C14.167 2 16.7 4.53302 16.7 7.66038C16.7005 9.12503 16.1331 10.5328 15.117 11.5877C14.101 12.6427 12.7155 13.2625 11.2519 13.317L11.0396 13.3208C7.91224 13.3208 5.37922 10.7877 5.37922 7.66038C5.3787 6.19573 5.94611 4.78793 6.96215 3.73301C7.97819 2.67809 9.3637 2.05823 10.8273 2.00377L11.0396 2ZM11.0396 3.88679C8.9547 3.88679 7.26602 5.57547 7.26602 7.66038C7.26602 9.74528 8.9547 11.434 11.0396 11.434C13.1245 11.434 14.8132 9.74528 14.8132 7.66038C14.8132 5.57547 13.1245 3.88679 11.0396 3.88679Z" fill="#A6A6A6"/>
                    </svg>
                </MoreLink>
                <MoreLink href={"/news-and-trends"} title={"News and Trends"}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.86185 6.71118H18.1379" stroke="#A6A6A6" stroke-width="1.7" stroke-linecap="square"/>
                        <path d="M19.7934 10.3821H4.20657C3.57988 10.3821 3.10763 10.9519 3.22394 11.5677L4.75867 19.6929C4.84789 20.1653 5.26058 20.5073 5.74129 20.5073H18.2587C18.7394 20.5073 19.1521 20.1653 19.2413 19.6929L20.7761 11.5677C20.8924 10.9519 20.4201 10.3821 19.7934 10.3821Z" stroke="#A6A6A6" stroke-width="1.7" stroke-linecap="square"/>
                        <path d="M8.76077 3.49268H15.2394" stroke="#A6A6A6" stroke-width="1.7" stroke-linecap="square"/>
                    </svg>

                </MoreLink>
                <MoreLink href={"/videos"} title={"Videos"}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 20H20V22H12C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22.0032 14.1642 21.3011 16.2705 20 18H17.292C18.6963 16.7616 19.6217 15.0698 19.907 13.2193C20.1924 11.3689 19.8197 9.47692 18.8538 7.87296C17.8879 6.26899 16.39 5.05463 14.6209 4.44134C12.8519 3.82805 10.9237 3.85468 9.1723 4.5166C7.42086 5.17852 5.95708 6.43379 5.03584 8.06382C4.1146 9.69385 3.79428 11.5954 4.13066 13.4372C4.46704 15.2791 5.4388 16.9447 6.87673 18.1439C8.31466 19.343 10.1277 19.9999 12 20ZM12 10C11.4696 10 10.9609 9.78929 10.5858 9.41421C10.2107 9.03914 10 8.53043 10 8C10 7.46957 10.2107 6.96086 10.5858 6.58579C10.9609 6.21071 11.4696 6 12 6C12.5304 6 13.0391 6.21071 13.4142 6.58579C13.7893 6.96086 14 7.46957 14 8C14 8.53043 13.7893 9.03914 13.4142 9.41421C13.0391 9.78929 12.5304 10 12 10ZM8 14C7.46957 14 6.96086 13.7893 6.58579 13.4142C6.21071 13.0391 6 12.5304 6 12C6 11.4696 6.21071 10.9609 6.58579 10.5858C6.96086 10.2107 7.46957 10 8 10C8.53043 10 9.03914 10.2107 9.41421 10.5858C9.78929 10.9609 10 11.4696 10 12C10 12.5304 9.78929 13.0391 9.41421 13.4142C9.03914 13.7893 8.53043 14 8 14ZM16 14C15.4696 14 14.9609 13.7893 14.5858 13.4142C14.2107 13.0391 14 12.5304 14 12C14 11.4696 14.2107 10.9609 14.5858 10.5858C14.9609 10.2107 15.4696 10 16 10C16.5304 10 17.0391 10.2107 17.4142 10.5858C17.7893 10.9609 18 11.4696 18 12C18 12.5304 17.7893 13.0391 17.4142 13.4142C17.0391 13.7893 16.5304 14 16 14ZM12 18C11.4696 18 10.9609 17.7893 10.5858 17.4142C10.2107 17.0391 10 16.5304 10 16C10 15.4696 10.2107 14.9609 10.5858 14.5858C10.9609 14.2107 11.4696 14 12 14C12.5304 14 13.0391 14.2107 13.4142 14.5858C13.7893 14.9609 14 15.4696 14 16C14 16.5304 13.7893 17.0391 13.4142 17.4142C13.0391 17.7893 12.5304 18 12 18Z" fill="#A6A6A6"/>
                    </svg>
                </MoreLink>
                <MoreLink href={"/faq"} title={"FAQ and Resources"}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.8842 7.04929V4.28897C16.8842 3.65272 16.2979 3.17826 15.6756 3.31096L5.80022 5.41697C5.33869 5.51539 5.00879 5.92307 5.00879 6.39498V20.0001C5.00879 20.5523 5.4565 21.0001 6.00879 21.0001C10.0028 21.0001 13.9967 21.0001 17.9907 21.0001C18.543 21.0001 18.9907 20.5523 18.9907 20.0001V8.04929C18.9907 7.49701 18.543 7.04904 17.9907 7.0523C13.6706 7.07779 9.39346 7.25895 5.06718 7.34607" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                        <path d="M9.52539 12.0723H12.3101M9.52539 15.8778H14.4731" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                    </svg>
                </MoreLink>
                <MoreLink href={"/cryptocurrencies"} title={"Cryptocurrencies"}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                        <path d="M14.2238 8.18774H11.0469C9.99417 8.18774 9.14072 9.04116 9.14072 10.0939C9.14072 11.1466 9.99417 12.0001 11.0469 12.0001H12.953C14.0057 12.0001 14.8592 12.8535 14.8592 13.9062C14.8592 14.9589 14.0057 15.8124 12.953 15.8124H9.7761" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                        <path d="M12 6.79102V17.2087" stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="square"/>
                    </svg>

                </MoreLink>

            </ul>
        </div>
    );
};

export default MoreOptions;
