import React, {useState, Suspense, useEffect, useCallback} from 'react';
import MobilePinnedMainHeader from "../../../Sections/MobilePinnedMainHeader";
import s from "./style.module.scss"
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import {useDropzone} from "react-dropzone";
import {useMutation, useQuery, useQueryClient} from "react-query";
import axiosClient from "../../../Api/apiClient";
import {Axios} from "axios";
import CustomSpinner from "../../../Utility/CustomSpinner";
import useUploadAttachment from "../../../Hooks/useUploadAttachment";
import {Link, useNavigate} from "react-router-dom";
import {useGetProfile} from "../../../Hooks/useGetProfile";
import BtnTextForMutation from "../../../Components/BtnTextForMutation";
import {ClipLoader} from "react-spinners";
import PageTitle from "../../../Components/PageTitle/PageTitle";

const AddNewCourse = () => {
    PageTitle("Add Course")

    const [fillStep,setFillStep]=useState(0)

    const [value, setValue] = useState('');
    const [title,setTitle]=useState("")
    const [addedFile,setAddedFile] = useState(null)

    const {id,mutation:uploadImgMutation} = useUploadAttachment()
    useEffect(()=>{
        console.log(id)
    },[id])
    const uploadFile =(file)=>{
        return uploadImgMutation.mutate({attach_type:"courses",file: file})
    }

    const { getRootProps, getInputProps} = useDropzone({
        multiple:false,
        onDrop: async files => {
            setAddedFile(files[0])
            await uploadFile(files[0])
        },
        accept:{
            'image/jpeg': ['.jpeg', '.png']
        },
    });

    const MyProfileEl =()=>{
        const me = useGetProfile()
        return(
            <div className="profile items-center flex gap-4 my-6">
                <div className="avatar shrink-0 w-12 h-12 aspect-square overflow-hidden rounded-full">
                        <img src={me.profile_image_url?me.profile_image_url:"/media/defaultAvatar.png"} className={"w-full h-full object-center object-cover"} alt="profile_img"/>
                </div>
                <div className="text">
                    <div className="name mb-2 font-bold">
                        {me.name}
                    </div>
                    {me.occupation &&<div className="role text-gray-50 text-12 h-6 px-3 bg-brown-50 w-fit">
                        {me.occupation}
                    </div>}
                </div>
            </div>
        )
    }


    const navigate = useNavigate()
    const postCourseMutation = useMutation({
        mutationKey:"courses",
        mutationFn: (course)=>axiosClient.post(`/courses`, course),
        onSuccess:()=>{
           navigate("/courses")
        }
    })

    const handleSubmit =async (status)=>{
        if(!postCourseMutation.isLoading && !postCourseMutation.isSuccess){
            const  courseData ={
                "title": title,
                "content": value,
                "status":status,
                "cover_file_id" : id
            }
            await postCourseMutation.mutateAsync(courseData).catch(err=>postCourseMutation.reset())
        }
    }

    return (
        <div className={s.container}>
            <MobilePinnedMainHeader  title={"Add"}/>
            <div className="plain flex h-full overflow-auto flex-grow flex-col overflow-hidden p-8">
                <div className="title text-24 mb-2 font-medium">
                    New <span className={"font-bold"}>Courses</span>
                </div>
                <div className="desc text-14 mb-8 text-gray-90">
                    Add your <span className={"font-bold"}>Course</span>
                </div>
                <div className={"editor-wrapper overflow-auto flex-grow gap-6 flex flex-col py-4 xl:py-10 px-8"}>
                    {fillStep === 0 ?
                        <>
                            <div className={"flex flex-col gap-3"}>
                                <label htmlFor="title" className={"label"}>Title *</label>
                                <input value={title} onInput={(e)=>setTitle(e.target.value)} type="text" name={"title"} placeholder={"Type title"}/>
                            </div>
                            <div className={" flex-grow gap-3 flex flex-col mt-6 "}>
                                <label htmlFor="Content" className={"label mb-4"}>Content</label>
                                <ReactQuill theme="snow" value={value} onChange={setValue}
                                            className={"!h-3/5 "}
                                            modules={{
                                                toolbar: {
                                                    container: [
                                                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                                        ['bold', 'italic', 'underline'],
                                                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                                        [{ 'align': [] }],
                                                        ['link', 'image'],
                                                        ['clean'],
                                                        [{ 'color': [] }]
                                                    ],
                                                    // handlers: {
                                                    //     image: this.imageHandler
                                                    // }
                                                },
                                            }}
                                />
                            </div>
                        </>
                    :
                        <div className={"h-full w-full flex-col lg:flex-row flex gap-12 justify-around mb-20 xl:gap-20"}>
                                <div className="w-full  lg:w-1/2">
                                    <div className={"box-title font-medium mb-6"}>Upload Cover</div>

                                    <form className="mt-4">
                                        <div {...getRootProps({className: '   mb-4 dropzone'})}>
                                            <input {...getInputProps()} />
                                            <div className="upload-box">
                                                <div>
                                                    <svg width="43" height="39" viewBox="0 0 43 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M13.5 27.5L21.5 19.5M21.5 19.5L29.5 27.5M21.5 19.5V37.5M37.5 28.9857C39.943 26.9681 41.5 23.9159 41.5 20.5C41.5 14.4249 36.5751 9.5 30.5 9.5C30.063 9.5 29.6541 9.27199 29.4322 8.89548C26.8241 4.46967 22.0089 1.5 16.5 1.5C8.21573 1.5 1.5 8.21573 1.5 16.5C1.5 20.6322 3.17089 24.3742 5.8739 27.0871" stroke="url(#paint0_linear_6438_5903)" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <defs>
                                                            <linearGradient id="paint0_linear_6438_5903" x1="21.5" y1="-18.8684" x2="21.5" y2="19.5" gradientUnits="userSpaceOnUse">
                                                                <stop stop-color="white"/>
                                                                <stop offset="1" stop-color="#D9D9D9"/>
                                                            </linearGradient>
                                                        </defs>
                                                    </svg>
                                                </div>
                                                <div>
                                                    Click to upload or drag and drop
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className={"h-full pb-6 lg:pb-0 flex flex-col"}>
                                    <div className="box-title">
                                        Preview
                                    </div>
                                    <div className="preview-box flex-grow relative flex flex-col">

                                        <div className="cover relative flex justify-center items-center w-full flex-grow">
                                            <div className={"absolute top-3 left-3"}>
                                                {
                                                    uploadImgMutation.isLoading ?
                                                    <div className={"rounded-full bg-brown-90 aspect-square w-12 scale-75 h-13 -translate-x-1/2 -translate-y-1/2 grid place-items-center"}>
                                                        <ClipLoader color={"#D9D9D9"}/>
                                                    </div>
                                                        :
                                                        addedFile &&
                                                        <button onClick={()=>setAddedFile(null)} className={"absolute bg-brown-80 cursor-pointer p-2 rounded-full -right-2 -bottom-3"}>
                                                            <svg id="Delete" width="24" height="24" viewBox="0 0 25 25" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M15.7124 4.64461L16.5306 6.4294H19.17C19.9815 6.4294 20.6394 7.08728 20.6394 7.89882V8.98702C20.6394 9.54188 20.1896 9.99168 19.6348 9.99168H5.86501C5.31015 9.99168 4.86035 9.54188 4.86035 8.98702V7.89882C4.86035 7.08728 5.51823 6.4294 6.32978 6.4294H8.9692L9.7874 4.64461C10.0329 4.10901 10.5681 3.76562 11.1573 3.76562H14.3425C14.9317 3.76562 15.4668 4.10901 15.7124 4.64461Z"
                                                                    stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="round"
                                                                    stroke-linejoin="round"/>
                                                                <path
                                                                    d="M19.1004 10.0664V18.7521C19.1004 20.4167 17.7677 21.7661 16.1238 21.7661H9.37696C7.73305 21.7661 6.40039 20.4167 6.40039 18.7521V10.0664"
                                                                    stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="round"
                                                                    stroke-linejoin="round"/>
                                                                <path d="M11.0596 13.582V18.0904M14.44 13.582V18.0904" stroke="#A6A6A6"
                                                                      stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                            </svg>
                                                        </button>
                                                }
                                            </div>
                                            {!addedFile ?
                                                "Cover"
                                                :
                                               <img className={"w-full h-full object-cover rounded-8"} src={URL.createObjectURL(addedFile)} alt=""/>
                                            }
                                        </div>
                                        <div className="prev-title text-18 font-medium mt-5">
                                            {title}
                                        </div>
                                        <Suspense>
                                            <MyProfileEl/>
                                        </Suspense>
                                    </div>
                                </div>
                        </div>
                    }
                </div>
                <div className="btn-box flex gap-2  ml-auto mt-8">
                    {
                        fillStep==0?
                            <>
                                <Link to={"/courses"}   className="btn !bg-brown-100 ">
                                    cancel
                                </Link>

                                <button disabled={!title || !value} onClick={()=>setFillStep(prev=>prev+1)}  className="btn w-[160px] !bg-gray-50 !text-black">
                                    Next
                                </button>
                            </>
                            :
                            <>
                                <button onClick={()=>setFillStep(0)}  className="btn !bg-brown-100 ">
                                    Back
                                </button>
                                <button disabled={!id || uploadImgMutation.isLoading} onClick={()=> handleSubmit("draft")}  className="btn !bg-brown-100">
                                    <BtnTextForMutation errorText={"error"} mutation={postCourseMutation} successText={"Saved!"} defaultText={"Save draft"}/>
                                </button>
                                <button disabled={!id || uploadImgMutation.isLoading} onClick={()=> handleSubmit("published")}  className="btn w-[160px] !bg-gray-50 !text-black">
                                    <BtnTextForMutation errorText={"error"} mutation={postCourseMutation} successText={"Published!"} defaultText={"Publish"}/>
                                </button>
                            </>
                    }

                </div>
            </div>
        </div>
    );
};

export default AddNewCourse;
