import React, {Suspense, useState} from 'react';
import classNames from "classnames";
import s from "./styles.module.scss"
import MobilePinnedMainHeader from "../../Sections/MobilePinnedMainHeader";
import Tabbar from "../../Sections/Tabbar/Tabbar";
import NewsBox from "./Newsbox";
import {useQuery} from "react-query";
import {FullPageLoading} from "../../Components/LoadingPage";
import TradePostBox from "../TradingStrategies/TradePostBox";
import AdminCanView from "../../Components/ViewLimit/AdminCanView";
import {Link} from "react-router-dom";
import axiosClient from "../../Api/apiClient";
import ResponsivePagination from "react-responsive-pagination";
import PageTitle from "../../Components/PageTitle/PageTitle";
const NewsAndTrends = () => {
    PageTitle("News and trends")

    const [isAllTab,setIsAllTab]=useState(true)
    const [page,setPage]=useState(1)
    const [totalPages,setTotalPages]=useState(1)
    const perpage = 6




    const PageData=()=>{
        const { data  } = useQuery({
            queryKey:["/news-and-trends",page,isAllTab],
            queryFn :()=>axiosClient.get(`/news-and-trends?page=${page}&per_page=${perpage}${isAllTab==false ? `&bookmarked=1`:""}`),

        })
        const listData = data.data.resulting_data.data
        //

        const totalItems = data.data.resulting_data.meta.total
        setTotalPages(Math.ceil(totalItems/perpage))

        return(
            <div className={"max-w-[800px] h-full mx-auto"}>
                <>
                    {listData.length==0 ?
                        <div className={"w-full h-full flex-col gap-4 flex justify-center text-center items-center"}>
                            <svg width="99" height="98" viewBox="0 0 99 98" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.4" d="M71.6957 81.2677L74.7987 82.7532C78.0604 84.3147 81.8347 81.9349 81.8306 78.3155L81.791 28.2768C81.791 18.3842 76.2883 12.25 66.4119 12.25H36.6069C26.7064 12.25 21.3231 18.3842 21.3231 28.2768L21.2516 78.3077C21.2476 81.9272 25.0219 84.3106 28.2876 82.7455L31.3667 81.2714C33.218 80.3816 35.3594 80.3179 37.2625 81.0966L46.0267 84.6683C49.5588 86.1106 53.5196 86.1106 57.0517 84.6683L65.7998 81.0966C67.7031 80.3179 69.8444 80.3816 71.6957 81.2677Z" stroke="white" stroke-width="6.125" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M41.8597 59.0171H61.0012" stroke="white" stroke-width="6.125" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M57.649 46.698L45.3925 34.4453M45.4002 46.7016L57.6567 34.4453" stroke="white" stroke-width="6.125" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <div className={"text-24 lg:text-48 max-w-[520px] font-semibold"}>
                                {isAllTab ?
                                    "News has not been added yet "
                                    :
                                    "You have not bookmarked any news."
                                }
                            </div>
                            <p className={"text-14 max-w-[400px] text-gray-90"}>
                                {isAllTab ?""  :
                                    "When you bookmark a news, it will appear here."
                                }
                            </p>
                            {isAllTab && <AdminCanView>
                                <Link to={"add"} className={"btn mt-1 grey-50  btn-normal"}>
                                    Add new
                                </Link>
                            </AdminCanView>}

                        </div>

                :
                    <div className={"wrapper grid gap-3"}>
                        {[...listData].map(i=>(
                            <NewsBox isLiked={i.is_liked} isBookmarked={i.is_bookmarked} likes={i.likes} id={i.id} key={i.id} author={i.owner} date={i.created_at} comments={i.comments} title={i.title} url={i.link}/>
                        ))}
                        <div className={"mt-4 paginator-wrapper"}>
                            <ResponsivePagination
                                current={page}
                                total={totalPages}
                                onPageChange={setPage}
                            />
                        </div>
                    </div>

                    }
                </>

            </div>

        )
    }
    return (
        <div className={classNames(s.container,"flex flex-col h-full")}>
            <MobilePinnedMainHeader title={"News And Trends"}>
                    <AdminCanView>
                        <Link to={"add"}  className="btn grey-50">
                            Add new post
                        </Link>
                    </AdminCanView>
            </MobilePinnedMainHeader>
            <Tabbar>
                <div onClick={()=>setIsAllTab(true)} className={classNames("tab cursor-pointer",isAllTab&&"active")}>
                    All
                </div>
                <div onClick={()=>setIsAllTab(false)} className={classNames("tab cursor-pointer",!isAllTab&&"active")}>
                    Marked
                    {/*<div className="sqr rounded-[6px] grid place-items-center aspect-square w-6 bg-brown-110 text-12 text-gray-90 font-medium">*/}
                    {/*    4*/}
                    {/*</div>*/}
                </div>

            </Tabbar>
            <div className={"flex-grow py-8 pad-layout overflow-auto "}>
                <Suspense fallback={<FullPageLoading/>}>
                    <PageData/>
                </Suspense>
            </div>

        </div>
    );
};

export default NewsAndTrends;
