import React, {useState,Suspense} from 'react';
import s from "./styles.module.scss"
import classNames from "classnames";
import MobilePinnedMainHeader from "../../../Sections/MobilePinnedMainHeader";
import {useMutation, useQuery} from "react-query";
import axiosClient from "../../../Api/apiClient";
import CustomSpinner from "../../../Utility/CustomSpinner";
import {Link, useNavigate} from "react-router-dom";
import BtnTextForMutation from "../../../Components/BtnTextForMutation";
import PageTitle from "../../../Components/PageTitle/PageTitle";
import {useAlert} from "react-alert";
const NewPost = () => {
    PageTitle("Add News and trends")

    const [fillStep,setFillStep]=useState(0)
    const navigate =useNavigate()
    const [data,setData]=useState({
        "title" : "",
        "link" : ""
    })
    const postMutation = useMutation({
        mutationFn: (d)=>axiosClient.post(`/news-and-trends`, d)
    })
    const handleChange =(e)=>{
        const { name, value } = e.target;
        setData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    }
    const alert = useAlert();

    function isUrlValid(userInput) {
        let res = userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
        if(res == null)
            return false;
        else
            return true;
    }

    const handleSubmit =()=>{

        if (!isUrlValid(data.link)) {
            alert.error("This is not a link!")
        }
        else{
            if(!postMutation.isLoading && !postMutation.isSuccess) {
                postMutation.mutateAsync(data).then(() => {
                    setTimeout(() => {
                        navigate("/news-and-trends")
                    }, 2000)
                })
            }
        }

    }
    const Profile=()=>{
        const {data} = useQuery("/auth/me",{
            refetchOnWindowFocus:false,
            cacheTime:Infinity,
            staleTime:Infinity
        })
        const me = data.resulting_data

        return(
            <div className="profile mb-5 flex gap-3 items-center">
                <div className="circle w-12 h-12 rounded-full overflow-hidden bg-white">
                    <img src={me.profile_image_url?me.profile_image_url : "/media/defaultAvatar.png"} className={"w-full h-full object-cover"} alt=""/>
                </div>
                <div>
                    {me.name}
                </div>
            </div>
        )
    }
    return (
        <div className={classNames(s.container,"flex flex-col h-full")}>
            <MobilePinnedMainHeader title={"News and Trends"}/>
            <div className={"flex-grow pad-layout py-8 flex items-center justify-center"}>
                    <div>
                        <div className="title text-24  font-semibold text-gray-50 mb-10">
                            New post
                        </div>
                        <div className={"box md:w-[700px] p-12"}>
                           <Suspense fallback={<div className={"profile"}></div>}>
                            <Profile/>
                           </Suspense>
                            <div className="input-box flex flex-col gap-2 mb-8">
                                <label htmlFor="">
                                    Title *
                                </label>
                                <input name={"title"} value={data.title} onChange={handleChange} type="text" placeholder={"Type title"}/>
                            </div>
                            <div className="input-box flex flex-col gap-2 mb-8">
                                <label htmlFor="">
                                    Paste your link
                                </label>
                                <div className={"relative "}>
                                    <div className={"absolute w-fit my-auto inset-0 h-fit left-3"}>
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M7.59825 5.66602H6.53249C4.37999 5.66602 2.625 7.42102 2.625 9.57352C2.625 11.7193 4.37999 13.4803 6.53249 13.4803H7.59825"
                                                stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="round"
                                                stroke-linejoin="round"/>
                                            <path
                                                d="M11.1519 5.66602H12.2176C14.3701 5.66602 16.1251 7.42102 16.1251 9.57352C16.1251 11.7193 14.3701 13.4803 12.2176 13.4803H11.1519"
                                                stroke="#A6A6A6" stroke-width="1.5" stroke-linecap="round"
                                                stroke-linejoin="round"/>
                                            <path d="M6.91553 9.57422H11.8351" stroke="#A6A6A6" stroke-width="1.5"
                                                  stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </div>
                                    <input type="text" name={"link"} value={data.link} onChange={handleChange} className={"w-full pl-10"} placeholder={"Paste your link here"}/>
                                </div>
                            </div>
                            <div className="btn-box mt-10 flex gap-2 w-fit ml-auto">
                                <Link to={"/news-and-trends"} className="btn w-[140px] dark">
                                    Cancel
                                </Link>
                                <button onClick={handleSubmit} className="btn w-[140px] !bg-gray-50 !text-black">
                                    <BtnTextForMutation mutation={postMutation} defaultText={"Publish"}/>
                                </button>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    );
};

export default NewPost;
