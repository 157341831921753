import React, {useEffect} from 'react';
import {BrowserRouter, Route, Routes, useNavigate} from "react-router-dom";
import Layout from "../Sections/Layout";
import Chat from "./Chat";
import ProfilePage from "./ProfilePage";
import Courses from "./Courses";
import AddNewCourse from "./Courses/AddNewCourse";
import CourseEdit from "./Courses/CourseEdit";
import CoursesDraft from "./Courses/CoursesDraft";
import CourseInfoPage from "./Courses/CourseInfoPage";
import Mentors from "./Mentors";
import Availability from "./Mentors/Availablility";
import MentorsEdit from "./Mentors/MentorsEdit";
import Videos from "./Videos";
import VideoPage from "./Videos/VideoPage";
import AddNewVideo from "./Videos/AddNew";
import EditVideo from "./Videos/EditVideo";
import Cryptocurrency from "./Cryptocurrency";
import CryptoCurrencyCreate from "./Cryptocurrency/Create";
import CryptoCurrencyEdit from "./Cryptocurrency/Edit";
import Activities from "./Activities";
import NewsAndTrends from "./News&Trends";
import NewPost from "./News&Trends/NewPost";
import NewsEdit from "./News&Trends/Edit/NewsEdit";
import TransactionList from "./Session/TransactionList";
import Session from "./Session";
import SessionAdmin from "./Session/SessionAdmin";
import SessionSetAvailability from "./Session/SetAvailability";
import TradingStrategies from "./TradingStrategies";
import EditStrategy from "./TradingStrategies/EditStrategy";
import TradingStrategyDetails from "./TradingStrategies/TradingStrategyDetails";
import AddStrategy from "./TradingStrategies/AddStrategy";
import FAQ from "./FAQ";
import AddNewFaq from "./FAQ/Create/AddNewFaq";
import EditFaq from "./FAQ/Edit/EditFaq";
import Calender from "./Calender";
import Subscription from "./Subscription";
import SubscriptionAdmin from "./Subscription/SubscriptionAdmin";
import LoginPage from "./LoginRegister/LoginPage";
import RegisterPage from "./LoginRegister/RegisterPage";
import {useGetProfile} from "../Hooks/useGetProfile";
import {useAtom} from "jotai";
import {meAtom} from "../App";
import NewPassword from "./LoginRegister/NewPassword";
import ForgotPassword from "./LoginRegister/ForgotPassword";
import ThanksForRegister from "./LoginRegister/ThanksForRegister";
import AddedUser from "./LoginRegister/AddedUser";
import DeclinedUser from "./LoginRegister/DeclinedUser";

const RoutesComponent = () => {
    const [me] = useAtom(meAtom)
    const isPremiumUser =  me?.subscribed


    return (
        <BrowserRouter>
            <Routes>
                <Route path={"/login"} element={
                    <LoginPage/>
                }/>
                <Route path={"/sign-up"} element={
                    <RegisterPage/>

                }/>
                <Route path={"/new-password"} element={
                    <NewPassword/>
                }/>
                <Route path={"/forgot-password"} element={
                    <ForgotPassword/>
                }/>
                {
                    !isPremiumUser ?
                        <>
                            <Route path={"/*"}  element={
                                <Layout>
                                    <Subscription/>
                                </Layout>
                            }/>
                            <Route exact path={"/"} element={
                                <Layout>
                                    <Chat/>
                                </Layout>
                            } />
                            <Route exact path={"/chats"} element={
                                <Layout>
                                    <Chat/>
                                </Layout>
                            } />
                            <Route  path={"/chats/*"} element={
                                <Layout NoMobileNavigator>
                                    <Chat/>
                                </Layout>
                            } />

                            <Route path={"/activities"} element={
                                <Layout>
                                    <Activities/>
                                </Layout>
                            } />
                            <Route path={"/profile"} element={
                                <Layout>
                                    <ProfilePage/>
                                </Layout>
                            }/>

                            <Route path={"/login"} element={
                                <LoginPage/>
                            }/>
                            <Route path={"/thanks_for_registering"} element={
                                <ThanksForRegister/>
                            }/>
                            <Route path={"/user-added"} element={
                                <AddedUser/>
                            }/>
                            <Route path={"/user-rejected"} element={
                                <DeclinedUser/>
                            }/>
                            <Route path={"/pre_register"} element={
                                <RegisterPage/>
                            }/>
                        </>
                        :
                        <>
                            <Route exact path={"/"} element={
                                <Layout>
                                    <Chat/>
                                </Layout>
                            } />
                            <Route exact path={"/chats"} element={
                                <Layout>
                                    <Chat/>
                                </Layout>
                            } />
                            <Route  path={"/chats/*"} element={
                                <Layout NoMobileNavigator>
                                    <Chat/>
                                </Layout>
                            } />

                            <Route path={"/activities"} element={
                                <Layout>
                                    <Activities/>
                                </Layout>
                            } />
                            <Route path={"/profile"} element={
                                <Layout>
                                    <ProfilePage/>
                                </Layout>
                            }/>
                            <Route  path={"/courses"} element={
                                <Layout >
                                    <Courses/>
                                </Layout>
                            } />
                            <Route  path={"/courses/add"} element={
                                <Layout >
                                    <AddNewCourse/>
                                </Layout>
                            } />
                            <Route  path={"/courses/edit/*"} element={
                                <Layout >
                                    <CourseEdit/>
                                </Layout>
                            } />
                            <Route  path={"/courses/coursesDraft"} element={
                                <Layout >
                                    <CoursesDraft/>
                                </Layout>
                            } />
                            <Route  path={"/courses/*"} element={
                                <Layout >
                                    <CourseInfoPage/>
                                </Layout>
                            } />



                            <Route  path={"/mentors"} element={
                                <Layout >
                                    <Mentors/>
                                </Layout>
                            } />
                            <Route  path={"/mentors/availability"} element={
                                <Layout >
                                    <Availability/>
                                </Layout>
                            } />
                            <Route  path={"/mentors/edit"} element={
                                <Layout >
                                    <MentorsEdit/>
                                </Layout>
                            } />


                            <Route  path={"/videos"} element={
                                <Layout >
                                    <Videos/>
                                </Layout>
                            } />
                            <Route  path={"/videos/*"} element={
                                <Layout >
                                    <VideoPage/>
                                </Layout>
                            } />

                            <Route  path={"/videos/add"} element={
                                <Layout >
                                    <AddNewVideo/>
                                </Layout>
                            } />
                            <Route  path={"/videos/edit/*"} element={
                                <Layout >
                                    <EditVideo/>
                                </Layout>
                            } />
                            <Route  path={"/cryptocurrencies"} element={
                                <Layout >
                                    <Cryptocurrency/>
                                </Layout>
                            } />
                            <Route  path={"/cryptocurrencies/add"} element={
                                <Layout >
                                    <CryptoCurrencyCreate/>
                                </Layout>
                            } />
                            <Route  path={"/cryptocurrencies/edit/*"} element={
                                <Layout >
                                    <CryptoCurrencyEdit/>
                                </Layout>
                            } />

                            <Route path={"/news-and-trends"} element={
                                <Layout>
                                    <NewsAndTrends/>
                                </Layout>
                            } />
                            <Route path={"/news-and-trends/add"} element={
                                <Layout>
                                    <NewPost/>
                                </Layout>
                            } />
                            <Route path={"/news-and-trends/edit/*"} element={
                                <Layout>
                                    <NewsEdit/>
                                </Layout>
                            } />

                            <Route path={"/session/transactions-list"} element={
                                <Layout>
                                    <TransactionList/>
                                </Layout>
                            } />

                            <Route path={"/sessions"} element={
                                <Layout>
                                    <Session/>
                                </Layout>
                            } />
                            <Route path={"/session/admin"} element={
                                <Layout>
                                    <SessionAdmin/>
                                </Layout>
                            } />
                            <Route path={"/session/set-availability"} element={
                                <Layout>
                                    <SessionSetAvailability/>
                                </Layout>
                            } />

                            <Route path={"/trading-strategies"} element={
                                <Layout>
                                    <TradingStrategies/>
                                </Layout>
                            } />
                            <Route path={"/trading-strategies/edit/*"} element={
                                <Layout>
                                    <EditStrategy/>
                                </Layout>
                            } />
                            <Route  path={"/trading-strategies/*"} element={
                                <Layout>
                                    <TradingStrategyDetails/>
                                </Layout>
                            } />
                            <Route path={"/trading-strategies/add"} element={
                                <Layout>
                                    <AddStrategy/>
                                </Layout>
                            } />

                            <Route path={"/faq"} element={
                                <Layout>
                                    <FAQ/>
                                </Layout>
                            } />
                            <Route path={"/faq/add"} element={
                                <Layout>
                                    <AddNewFaq/>
                                </Layout>
                            } />
                            <Route path={"/faq/edit/*"} element={
                                <Layout>
                                    <EditFaq/>
                                </Layout>
                            } />


                            <Route path={"/calendar-and-ideas/*"} element={
                                <Layout>
                                    <Calender/>
                                </Layout>
                            } />

                            <Route path={"/subscription"} element={
                                <Layout>
                                    <Subscription/>
                                </Layout>
                            } />

                            <Route path={"/subscription/admin"} element={
                                <Layout>
                                    <SubscriptionAdmin/>
                                </Layout>
                            } />
                            </>

                }
            </Routes>

        </BrowserRouter>
    );
};

export default RoutesComponent;
