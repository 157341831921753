import React, {Suspense, useState} from 'react';
import classNames from "classnames";
import s from "../Create/styles.module.scss"
import MobilePinnedMainHeader from "../../../Sections/MobilePinnedMainHeader";
import {useEditGetAndPost} from "../../../Hooks/useEditGetAndPost";
import BtnTextForMutation from "../../../Components/BtnTextForMutation";
import {Link} from "react-router-dom";
import PageTitle from "../../../Components/PageTitle/PageTitle";
import {useAlert} from "react-alert";
const EditFaq = () => {
    PageTitle("Edit frequently asked questions")
    const alert = useAlert()
    const PageLoadedContent =()=>{

        const {fetchedData,handleSubmitEdit,postMutation} = useEditGetAndPost("/posts","/faq")
        const [title,setTitle]=useState(fetchedData.title)
        const [desc,setDesc]=useState(fetchedData.description)

        const handleSubmit =()=>{
            handleSubmitEdit( {
                title: title,
                description:desc
            })
        }


        return(
            <>
                <div className="box p-4 md:p-8 mb-4 md:mb-10  w-full">
                    <div className="text-18 mb-6">
                        Edit your post
                    </div>
                    <div className="left flex-grow-[2] flex flex-col justify-center">
                        <div className="input-box mb-10 flex flex-col gap-3">
                            <label className="label">Title</label>
                            <input value={title} onChange={(e)=>setTitle(e.target.value)} placeholder={"Type here"} type="text"/>
                        </div>
                        <div className="input-box flex flex-col gap-3">
                            <label className="label">Description </label>
                            <textarea className={"resize-none h-[200px]"}
                                      placeholder={"Description here"}
                                      type="text"
                                       onChange={(e)=>setDesc(e.target.value)}
                                      value={desc}
                            />
                        </div>
                    </div>
                </div>
                <div className="btn-wrapper md:w-fit ml-auto flex flex-col md:flex-row w-full gap-3 ">
                    <Link to={"/faq"} className="btn dark big">Cancel</Link>
                    <button disabled={!desc || !title} onClick={handleSubmit} className="btn grey-50 big">
                        <BtnTextForMutation defaultText={"Post"} mutation={postMutation} />
                    </button>
                </div>
            </>
        )
    }
    return (
        <div className={classNames("flex flex-col h-full",s.container)}>
            <MobilePinnedMainHeader title={"Edit"} hasBackBtn/>
            <div className={"flex-grow flex p-8 flex-col font-medium justify-center items-center "}>
                <div className={"max-w-[750px] flex flex-col w-full"}>
                   <Suspense>
                       <PageLoadedContent/>
                   </Suspense>
                </div>
            </div>
        </div>
    );
};

export default EditFaq;
